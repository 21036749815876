import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b2048cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-sidebar__item" }
const _hoisted_2 = { class: "main-sidebar__item-icon" }
const _hoisted_3 = { class: "main-sidebar__item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      to: _ctx.route,
      class: "menu-item main-sidebar__item-link",
      "exact-active-class": "main-sidebar__item-link_active"
    }, {
      default: _withCtx(({ isExactActive, isActive }) => [
        _createElementVNode("span", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.sidebarIconComponentName(isExactActive || isActive))))
        ]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}