import { createRouter, createWebHistory } from 'vue-router';

import { RouteName } from '@/app/types/app/routes';

import env from '@/app/env/env';
import ClientResponseError from '@/app/errors/ClientResponseError';
import SimpleClientError from '@/app/errors/SimpleClientError';

import dictionaries from '@/router/middleware/global/dictionaries.global';
import fetchOtherData from '@/router/middleware/global/fetchOtherData.global';
import organizations from '@/router/middleware/global/organizations.global';
import serverStatus from '@/router/middleware/global/serverStatus.global';
import signature from '@/router/middleware/global/signature.global';
import user from '@/router/middleware/global/user.global';
import verification from '@/router/middleware/global/verification.global';
import { resolveServicePageRoute } from '@/router/resources/routerResources';
import routes from '@/router/resources/routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  const toRouteName = to.name as RouteName;

  try {
    await serverStatus(to, from)
      .then(user)
      .then(organizations)
      .then(verification)
      .then(() => signature(to, from))
      .then(dictionaries);

    fetchOtherData();

    return;
  } catch (errorEvent) {
    if (env.buildMode === 'development') {
      console.error(errorEvent);
    }

    const error = errorEvent as Error | ClientResponseError | SimpleClientError;

    return resolveServicePageRoute(error, toRouteName);
  }
});

export default router;
