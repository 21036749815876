import {
  VerificationApprovalStatus,
  VerificationStateResult,
} from '@/app/types/api/resources/verification';
import {
  VerificationGetters,
  VerificationMutations,
  VerificationState,
} from '@/app/types/store/modules/verification';

const state = (): VerificationState => ({
  verification: null,
});

const mutations: VerificationMutations = {
  setVerification(state, verification) {
    state.verification = verification;
  },
};

const getters: VerificationGetters = {
  isOrganizationVerified: (state): boolean => {
    return (
      state.verification?.result === VerificationStateResult.Success &&
      state.verification?.manual_approval_status ===
        VerificationApprovalStatus.Approved
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
