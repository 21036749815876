import { AxiosResponse } from 'axios';

import { AuthApi } from '@/app/types/api/resources/auth';

import client from '@/app/api/client';

export default {
  logoutUser(): Promise<AxiosResponse<void>> {
    return client.post(AuthApi.Logout);
  },
};
