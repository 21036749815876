import { CryptoBalance, FiatBalance } from '@/app/types/api/resources/balances';
import { CryptoCurrency } from '@/app/types/app/currency';

export enum OrgAccountsApi {
  Accounts = '/api/accounts',
  AccountWithBalances = '/api/accounts/balances',
  AccountById = '/api/accounts/:id',
  TransferFiat = '/api/accounts/transfers/fiat',
}

export interface OrgAccountsResponse {
  id: number;
  name: string;
  number: string;
  created_at: string;
}

export interface OrgAccountsWithBalancesResponse extends OrgAccountsResponse {
  balances: OrgAccountsBalances;
}

export interface OrgAccountsBalances {
  crypto: CryptoBalance<CryptoCurrency, string>[];
  fiat: FiatBalance<string>[];
}

export interface OrgAccountsRequestBody {
  name: string;
}

export interface AccountRequestBody {
  account_id: number;
}

export interface TransferFiatRequestBody {
  from_account_id: number;
  to_account_id: number;
  fiat_amount: number;
  currency_id: number;
}
