export default {
  heading: '@:common.pagesTitle.contactUs',
  form: {
    heading: 'Reach us within 1 click!',
    description:
      'State your question or problem in the form below and get a reply from our friendly Customer Success team right into your registration email!',
    subject: 'Subject',
    message: 'Message',
    submitButton: 'Send',
  },
  success: {
    heading: 'Thank you for reaching out to us!',
    description: [
      "Your message has been sent to our help desk and we'll do our best to respond as quickly as possible. Please keep an eye on your email inbox for a reply from us.",
      " If you don't hear back from us within 24 hours, please don't hesitate to follow up with us. We appreciate your patience and look forward to assisting you.",
    ],
    button: 'Send one more request',
  },
};
