import { computed, ref } from 'vue';

import { OnboardingStep } from '@/app/types/api/resources/users';

export default function () {
  const stepsInOrder = [
    OnboardingStep.Balances,
    OnboardingStep.ReceiveCrypto,
    OnboardingStep.SendCrypto,
    OnboardingStep.Settings,
  ];

  const currentStepIndex = ref<number>(0);

  const currentStep = computed<OnboardingStep>(
    () => stepsInOrder[currentStepIndex.value],
  );

  const totalSteps = computed(() => stepsInOrder.length);

  const isLastStep = computed(
    () => currentStepIndex.value === stepsInOrder.length - 1,
  );

  function setNextStepIndex() {
    if (isLastStep.value) {
      return;
    }

    currentStepIndex.value++;
  }

  return {
    currentStepIndex,
    currentStep,
    totalSteps,
    isLastStep,
    setNextStepIndex,
  };
}
