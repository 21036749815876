export default {
  additionModal: {
    heading: 'Add new bank account',
    subheading: 'Enter bank information',
    name: 'Name on account',
    bankName: 'Bank name',
    ibanDescription: 'Your international bank account number',
    bankAddress: 'Bank address',
    submitButton: '@:banking.additionModal.heading',
  },
  bankVerificationModal: {
    heading: 'Bank verification in progress',
    description: [
      'Your bank account record is being sent for verification.',
      'This process may take some time. Once it is successfully approved, you will be able to perform fiat operations such as withdrawals and adding fiat.',
    ],
    closeButton: 'Go to Bank Settings page',
  },
  bankDeletionModal: {
    heading: 'Bank Account Deletion:',
    areYouSure: 'Do you really want to delete this bank account?',
    actionCannotBeUndone: 'Please note that this action cannot be undone.',
  },
  bankAccountDeleted: 'The bank account {name} was successfully deleted',
  twoFactorSubmitButton: '@:common.buttons.confirm',
};
