export default {
  // profile
  profile: {
    navigation: {
      yourProfile: 'Your profile',
      banking: 'Banking',
    },
    infoSection: {
      title: 'General info',
      defaultCurrencyNote:
        'Default fiat currency is used to estimate your overall account balance',
      defaultCurrencyChangeSuccess: 'Default currency changed successfully',
    },
    passwordSection: {
      title: 'Password',
      twoFactorAction: 'Confirm password change',
      passwordChanged: 'Password changed successfully',
      inputLabels: {
        oldPass: 'Old password',
        newPass: 'New password',
        confirmPass: 'Confirm password',
      },
    },
    banking: {
      info: [
        'The list of linked banks below allows you to add or withdraw fiat from your account.',
        'You can add up to 5 bank accounts.',
      ],
      maxBankAccountsWarning: {
        reachedAccountsLimit: 'You have already linked five bank accounts.',
        addMore: 'If you want to add more, please contact our ',
        supportTeam: 'support team.',
      },
      addButton: 'Add new bank account',
      bankAccountsListEmpty:
        ' Before you can add or withdraw fiat from your account, you need to first link your bank account.',
      twoFactorAction: 'Delete account',
    },
    billing: {
      title: 'Billing information',
    },
  },
  // webhooks
  webhooks: {
    emptyMessageText: 'No webhooks yet.',
  },
  // api
  api: {
    descriptionLinkLabel: 'API documentation',
    keys: {
      title: 'API keys',
      webhooksLink: 'Webhooks',
      twoFactorAction: 'Generate new key',
      secretKeySuccess: 'Secret key successfully generated',
      postbackWarning:
        'Please specify a postback URL before generating API keys. The postback URL allows our system to send important data back to your application, enabling seamless communication and real-time updates between our services and your platform.',
      labels: {
        postbackUrl: 'Postback URL',
        apiKey: 'API key',
        secretKey: 'Secret key',
      },
      alert: {
        alertLinkLabel: 'Found in API documentation',
        tips: {
          first: 'Do not disclose your API Key or API Secret to anyone.',
          second: 'Add INXY Payments IP adress to your white list.',
        },
      },
    },
  },
  // payouts api
  payouts: {
    navigation: {
      account: 'Account',
      api: 'API',
    },
    apiEmptySettings: {
      prepend: 'Complete',
      link: 'API settings',
      append: ' to access this section',
    },
    description:
      'Payouts API allows you to automate outgoing payments processing.',
    // account
    account: {
      note: [
        'Funds for payouts will be withdrawn from the specified account.',
        'This setting affects payouts through the *Payouts API.*',
        'Account settings do not affect previously created payouts, only those created after the settings are applied.',
      ],
    },
  },
  // merchant
  merchant: {
    navigation: {
      publicProfile: 'Public profile',
      cryptocurrencies: 'Cryptocurrencies',
      account: 'Account',
      paylink: 'Payment link',
      donations: 'Donation link',
      api: 'API',
      notifications: 'Notifications',
    },
    settingsDescription: {
      description:
        'You can customise logo, display name, description and accepted cryptocurrencies in',
      link: 'public profile settings',
    },
    apiEmptySettings: {
      prepend: 'Complete',
      link: 'public profile settings',
      append: ' to access this section',
    },
    // publicProfile
    publicProfile: {
      note: [
        'This data is used for *Payment gateway, Payment link & Donation link.*',
        'You can customise logo, display name, description and accepted cryptocurrencies.',
      ],
      labels: {
        displayName: 'Display name',
        description: 'Description',
        defaultLink: 'Your website (optional)',
        defaultLinkTip:
          "This link will be used as a default 'Back to your website' URL",
      },
    },
    // cryptocurrencies
    cryptocurrencies: {
      exchangeNotes: {
        description: ' For automatic exchange, please note the following:',
        notes: [
          'The current exchange rate on the market will be applied.',
          'There is no guarantee of a successful exchange.',
          'Standard exchange fees will be charged.',
          'Fees are only applicable upon successful exchange.',
        ],
      },
      labels: {
        acceptedCryptos: 'Accepted cryptocurrencies',
        autoExchange: 'Auto-exchange',
        convertTo: 'Convert into',
      },
    },
    // account
    account: {
      note: [
        'The funds will be directed to the selected account.',
        'Account settings apply to the *Paygate API, Donation link,* and *Payment link.* ',
        'Account settings do not affect previously created sessions, only those created after the settings are applied.',
      ],
    },
    // paylink
    paylink: {
      title: 'Payment link',
      description:
        'Payment link takes customers directly to your Checkout page.',
      tip: 'Copy and paste it anywhere you need: website, email, media channel, social page or send it directly to your customer.',
    },
    // donate
    donate: {
      title: 'Donation link',
      description:
        'Donation link takes customers directly to your personal donation Checkout page.',
      tip: 'Copy and paste your donation link anywhere you need: website, email, media channel, social page.',
      presets: {
        title: 'Donation amounts preset',
        description:
          'You can predefy donation amounts as well as the amount selected by default. Supporters still can donate any other amount they want.',
        clearButton: 'Delete preset',
        inputLabel: 'Amount {count}',
      },
    },
    // gateway
    gateway: {
      description:
        'Payment gateway API allows you to automate payments processing.',
    },
    notifications: {
      note: [
        '*Enable* or *disable* email notifications for payment sessions that have timed out.',
        'This setting applies to all new payment sessions created after the changes are made.',
      ],
      labels: {
        email: 'Receive Payment Session Timeout Alerts via Email',
      },
    },
  },
};
