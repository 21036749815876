export default function (
  url: string,
  id: number | string | (number | string)[],
) {
  if (!Array.isArray(id)) {
    return url.replace(':id', id.toString());
  }

  let updatedUrl: string = url;

  id.forEach((el) => (updatedUrl = updatedUrl.replace(':id', el.toString())));

  return updatedUrl;
}
