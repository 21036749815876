import { HttpStatus } from '@/app/types/api/config/config';
import {
  AccessGetters,
  AccessMutations,
  AccessState,
} from '@/app/types/store/modules/access';

const state = (): AccessState => ({
  serverStatus: null,
  hasSignature: null,
});

const mutations: AccessMutations = {
  setServerStatus(state, status) {
    state.serverStatus = status;
  },
  setSignatureExistence(state, isExist) {
    state.hasSignature = isExist;
  },
};

const getters: AccessGetters = {
  isServerMaintaining: (state) =>
    state.serverStatus === HttpStatus.ServiceUnavailable,
};

export default {
  namespaced: true,

  state,
  getters,
  mutations,
};
