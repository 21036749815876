import { createI18n } from 'vue-i18n';

import { Locales } from '@/app/types/app/i18n';

import en from '@/lang/en';

export default createI18n({
  locale: Locales.En,
  allowComposition: true,
  messages: { en },
  legacy: false,
});
