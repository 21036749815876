import { StoreCommit } from '@/app/types/store/store';

import Store from '@/store';

import verification from '@/app/api/resources/verification';

export default class {
  checkVerification() {
    return verification.checkVerification().then(({ data }) => {
      Store.commit(StoreCommit.SetVerification, data.data);

      return Promise.resolve();
    });
  }
}
