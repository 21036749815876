import { ref } from 'vue';

import { TooltipAxis } from '@/composables/onboarding/useOnboardingRefs';

export default function () {
  const tooltipRef = ref();
  const tooltipAxis = ref(TooltipAxis.X);

  function getTooltipAxis() {
    if (!tooltipRef.value) {
      return TooltipAxis.X;
    }

    const popperEl = tooltipRef.value.$el.children[1];

    const placement = popperEl.dataset.popperPlacement;

    const axis = ['bottom', 'top'].includes(placement)
      ? TooltipAxis.Y
      : TooltipAxis.X;

    tooltipAxis.value = axis;
  }

  return { tooltipRef, tooltipAxis, getTooltipAxis };
}
