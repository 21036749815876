import Store from '@/store';

export const FiatFlowOrgIds = [
  '36fb119c-572c-4023-9a41-9d2475939f5c', // Clickadu prod
  '338bc72f-96d2-434b-bca0-16438c27d1c2', // Sergei Dev
  // '25b566ee-6a0b-4cbd-aba2-0f2231f936d2', // 0company Dev
]; // todo: whole flow is for Clickadu

export default () => {
  const { state } = Store;

  const orgUID = state.organizations.currentOrganizationUID;

  return FiatFlowOrgIds.includes(orgUID);
};
