export default {
  payments: {
    title: 'Incoming payments',
    filters: {
      invoiceType: 'Invoice type',
    },
    types: {
      orders: 'API',
      payLinks: 'Paylinks',
    },
    tabs: {
      invoices: 'Invoices',
      donations: 'Donations',
    },
    donations: {
      emptyMessageText:
        'No donations yet.\n\nAll new donations will appear here.',
    },
    invoices: {
      invoiceDetails: 'Invoice details',
      linkedTransactions: 'Linked transactions',
      seeAllTransactions:
        'See all linked transactions in transactions history ({amount})',
      emptyMessageText:
        'No invoices yet.\n\nAll new invoices will appear here.',
    },
  },
  massPayouts: {
    title: 'Mass payouts',
    description:
      'To add new mass payout upload CSV file with 2500 lines maximum. \n You can view template file to see the instructions.',
    emptyMessageText: 'No payouts yet.\n\nAll new payouts will appear here.',
    actions: {
      view: 'View',
      viewPay: 'View and pay',
      delete: 'Delete',
      rename: 'Rename',
      downloadFile: 'Download file',
      downloadReport: 'Download report',
    },
    details: {
      detailsTitle: 'Payout details',
      errorsTitle: 'Invalid data',
      paymentsTitle: 'Payments',
      checkoutTitle: 'Amounts by currency',
      timerRefresh: 'Refresh rates',
      timerNote:
        'Exchange rates are valid for a limited time due to currency volatility',
      timerExpiredNote: 'Exchange rates are outdated',
      notFoundError: 'Payout not found',
      balanceNote:
        'When depositing funds take into account exchange rates volatility and dynamic network fees',
    },
  },
};
