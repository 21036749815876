import getEnvNumber from '@/app/env/getEnvNumber';

export enum BuildMode {
  Production = 'production',
  Sandbox = 'sandbox',
  Develop = 'development',
}

export default {
  baseURL: process.env.VUE_APP_API_URL ?? '',
  authURL: process.env.VUE_APP_AUTH_URL ?? '',
  verificationURL: process.env.VUE_APP_VERIFICATION_URL ?? '',
  cookieName: process.env.VUE_APP_COOKIE_NAME ?? '',
  host: process.env.VUE_APP_HOST ?? '',
  contactEmail: process.env.VUE_APP_CONTACT_EMAIL ?? '',
  twoFactorMailDelay: getEnvNumber(process.env.VUE_APP_2FAUTH_MAIL_DELAY),
  ipAddress: process.env.VUE_APP_IP_ADDRESS ?? '',
  gatewayApiDocLink: 'https://documenter.getpostman.com/view/23101517/VUxNS84v',
  payersApiDocLink:
    'https://documenter.getpostman.com/view/23101517/2sA3kSnNpX',
  payoutTemplateLink: process.env.VUE_APP_API_PAYOUT_TEMPLATE_LINK ?? '',
  activeAccountCookieName: 'active-account',
  /**
   * TODO remake using NODE_ENV
   * https://cli.vuejs.org/guide/mode-and-env.html
   */
  buildMode: (process.env.VUE_APP_BUILD_MODE ?? BuildMode.Develop) as BuildMode,
  // sockets
  echoPublicKey: process.env.VUE_APP_ECHO_PUBLIC_KEY ?? '',
  // processing
  // Ethereum
  ethUsdtContract: process.env.VUE_APP_ETH_USDT_CONTRACT ?? '',
  ethUsdcContract: process.env.VUE_APP_ETH_USDC_CONTRACT ?? '',
  ethDaiContract: process.env.VUE_APP_ETH_DAI_CONTRACT ?? '',
  // BinanceSmartChain
  bscUsdtContract: process.env.VUE_APP_BSC_USDT_CONTRACT ?? '',
  bscUsdcContract: process.env.VUE_APP_BSC_USDC_CONTRACT ?? '',
  bscDaiContract: process.env.VUE_APP_BSC_DAI_CONTRACT ?? '',
  bscBusdContract: process.env.VUE_APP_BSC_BUSD_CONTRACT ?? '',
  // Polygon
  polygonUsdtContract: process.env.VUE_APP_POLYGON_USDT_CONTRACT ?? '',
  polygonUsdcContract: process.env.VUE_APP_POLYGON_USDC_CONTRACT ?? '',
  polygonDaiContract: process.env.VUE_APP_POLYGON_DAI_CONTRACT ?? '',
  // Tron
  tronUsdtContract: process.env.VUE_APP_TRON_USDT_CONTRACT ?? '',
  tronUsdcContract: process.env.VUE_APP_TRON_USDC_CONTRACT ?? '',
  // explorer
  transactionExplorerPathBsc:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_BSC ?? '',
  transactionExplorerPathEth:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_ETH ?? '',
  transactionExplorerPathBtc:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_BTC ?? '',
  transactionExplorerPathLtc:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_LTC ?? '',
  transactionExplorerPathDoge:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_DOGE ?? '',
  transactionExplorerPathPolygon:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_POLYGON ?? '',
  transactionExplorerPathTron:
    process.env.VUE_APP_TRANSACTION_EXPLORER_PATH_TRON ?? '',
  // chain
  ethChainId: getEnvNumber(process.env.VUE_APP_ETHEREUM_CHAIN_ID),
  ethChainName: process.env.VUE_APP_ETHEREUM_CHAIN_NAME ?? '',
  ethRpcUrl: process.env.VUE_APP_ETHEREUM_RPC_URL ?? '',
  ethSymbol: process.env.VUE_APP_ETHEREUM_SYMBOL ?? '',
  bscChainId: getEnvNumber(process.env.VUE_APP_BSC_CHAIN_ID),
  bscChainName: process.env.VUE_APP_BSC_CHAIN_NAME ?? '',
  bscRpcUrl: process.env.VUE_APP_BSC_RPC_URL ?? '',
  bscSymbol: process.env.VUE_APP_BSC_SYMBOL ?? '',
  polygonChainId: getEnvNumber(process.env.VUE_APP_POLYGON_CHAIN_ID),
  polygonChainName: process.env.VUE_APP_POLYGON_CHAIN_NAME ?? '',
  polygonRpcUrl: process.env.VUE_APP_POLYGON_RPC_URL ?? '',
  polygonSymbol: process.env.VUE_APP_POLYGON_SYMBOL ?? '',
  tronNode: process.env.VUE_APP_TRON_NODE ?? '',
  walletConnectProjectId: process.env.VUE_APP_WALLET_CONNECT_PROJECT_ID ?? '',
};
