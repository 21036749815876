import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d77ce7c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonMenu = _resolveComponent("ButtonMenu")!
  const _component_MainHeaderUser = _resolveComponent("MainHeaderUser")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_ButtonMenu, {
      class: "mr-1",
      "is-menu-opened": _ctx.isMenuOpened,
      onToggleMenu: _ctx.toggleMenu
    }, null, 8, ["is-menu-opened", "onToggleMenu"]),
    _createVNode(_component_MainHeaderUser)
  ]))
}