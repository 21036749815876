import {
  OrganizationsActions,
  OrganizationsMutations,
  OrganizationsState,
} from '@/app/types/store/modules/organizations';

import cookies from '@/plugins/cookies';

import env from '@/app/env/env';

const state = (): OrganizationsState => ({
  currentOrganizationUID: '',
  organizations: [],
  accounts: [],
  activeAccountId: null,
  allowedCryptoCurrencies: [],
});

const mutations: OrganizationsMutations = {
  setOrganizations(state, organizations) {
    state.organizations = organizations;
  },

  setCurrentOrganization(state, uid) {
    state.currentOrganizationUID = uid;
  },

  setOrgAccounts(state, accounts) {
    state.accounts = accounts;
  },

  setActiveAccount(state, accountId) {
    state.activeAccountId = accountId;
  },

  setAllowedCryptoCurrencies(state, cryptoCurrencies) {
    state.allowedCryptoCurrencies = cryptoCurrencies;
  },
};

const actions: OrganizationsActions = {
  setActiveAccount({ state, commit }) {
    const activeAccount = cookies.get(env.activeAccountCookieName);
    const defaultAccountId = state.accounts[0]?.id;

    if (!activeAccount) {
      commit('setActiveAccount', defaultAccountId);

      return;
    }

    const hasAccount = state.accounts.some((acc) => acc.id === +activeAccount);

    commit('setActiveAccount', hasAccount ? +activeAccount : defaultAccountId);
  },
  updateActiveAccount({ commit }, accountId: number) {
    commit('setActiveAccount', accountId);

    cookies.set(
      env.activeAccountCookieName,
      `${accountId}`,
      '1m',
      '/',
      env.host,
    );
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  actions,
};
