import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/withdraw',
    name: RouteName.Withdraw,
    redirect: { name: RouteName.WithdrawFiat },
    meta: { layout: Layout.Default },
    children: [
      {
        path: 'crypto',
        name: RouteName.WithdrawCrypto,
        component: () => import('@/views/ViewWithdraw/ViewWithdrawCrypto.vue'),
      },
      {
        path: 'fiat',
        name: RouteName.WithdrawFiat,
        component: () => import('@/views/ViewWithdraw/ViewWithdrawFiat.vue'),
      },
    ],
  },
];
