import authApi from '@/app/api/resources/auth';
import logoutAndRedirect from '@/app/helpers/logoutAndRedirect';

export default function () {
  async function logoutUser() {
    await authApi.logoutUser();

    logoutAndRedirect(true);
  }

  return { logoutUser };
}
