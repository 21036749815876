import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fb53e5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-tooltip__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, _mergeProps({ class: "app-tooltip" }, _ctx.tooltipAttrs), _createSlots({
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 2
  }, [
    (_ctx.$slots.content !== undefined)
      ? {
          name: "content",
          fn: _withCtx((props) => [
            _createElementVNode("article", _hoisted_1, [
              _renderSlot(_ctx.$slots, "content", _normalizeProps(_guardReactiveProps(props)), undefined, true)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1040))
}