import { AxiosRequestHeaders, AxiosResponse } from 'axios';

import { DictionaryResponse } from '@/app/types/api/resources/dictionaries';
import {
  User,
  UserChangePassword,
  UsersApi,
  UsersMeResponse,
} from '@/app/types/api/resources/users';

import client from '@/app/api/client';

export default {
  fetchMe(): Promise<AxiosResponse<UsersMeResponse>> {
    return client.get(UsersApi.Me);
  },

  validatePasswordChange(
    data: UserChangePassword,
  ): Promise<DictionaryResponse<[]>> {
    return client.put(UsersApi.ValidatePassChange, data);
  },

  changePassword(
    data: UserChangePassword,
    headers?: AxiosRequestHeaders,
  ): Promise<DictionaryResponse<[]>> {
    return client.put(UsersApi.ChangePassword, data, { headers });
  },

  completeOnboarding(): Promise<DictionaryResponse<User>> {
    return client.post(UsersApi.CompleteOnboarding);
  },
};
