export default {
  funding: {
    emptyMessageText: 'No funding instructions yet',
  },
  vatInvoices: {
    emptyMessageText: 'No invoices yet',
  },
  merchantReports: {
    emptyMessageText: 'No reports yet',
  },
};
