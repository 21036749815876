import { RouteLocationNormalized } from 'vue-router';

import { HttpStatus } from '@/app/types/api/config/config';

import Store from '@/store';
import AccessStoreService from '@/store/services/AccessStoreService';

import ClientResponseError from '@/app/errors/ClientResponseError';

const accessService = new AccessStoreService();

export default function (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const { getters } = Store;

    if (to.name === from.name) {
      return resolve();
    }

    function handleServerMaintaining() {
      if (getters['access/isServerMaintaining']) {
        return reject(
          ClientResponseError.createSimpleError(
            'Server down',
            HttpStatus.ServiceUnavailable,
          ),
        );
      }
    }

    handleServerMaintaining();

    return accessService
      .getServerStatus()
      .then(() => {
        handleServerMaintaining();

        return resolve();
      })
      .catch(reject);
  });
}
