import { AxiosResponse } from 'axios';

import { MaintenanceApi } from '@/app/types/api/resources/maintenance';

import client from '@/app/api/client';

export default {
  getServerStatus(): Promise<AxiosResponse<['ok']>> {
    return client.get(MaintenanceApi.HealthCheck);
  },
};
