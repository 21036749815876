export default {
  skipOnboarding: 'Skip onboarding',
  intro: {
    heading: 'Welcome to INXY',
    description:
      'You are now able to effortlessly send, receive, and manage your cryptocurrency assets. Would you be interested in exploring a brief tutorial?',
  },
  balances: {
    heading: 'Manage Your Assets',
    description: 'Here, you can effectively manage your assets:',
    points: [
      '*Balances: *Manage your accounts. View your account balances. Add or withdraw funds and access the exchange functionality. Download balance statements.',
      '*Transaction History: *Track all the movement of funds in your account through the Transaction History section.',
    ],
  },
  receiveCrypto: {
    heading: 'Receive Crypto',
    description: 'Use all the necessary tools to receive cryptocurrency:',
    points: [
      '*Incoming payments: *View and manage all incoming payments received through merchant tools.',
      '*Merchant settings: *Set up and manage the ways you would like to receive cryptocurrency. These can include Payment gateway API, Payment link, Donation link.',
    ],
  },
  sendCrypto: {
    heading: 'Send Crypto',
    description: 'Utilize all the necessary tools to send cryptocurrency:',
    points: [
      '*One-time Payment: *Make instant, one-time payments to other INXY users or external wallets.',
      '*Mass Payouts: *Use a file to send cryptocurrency to up to 2500 recipients at once.',
      '*Payouts settings: *Or use Payments API to automate your payout processes.',
    ],
  },
  settings: {
    heading: 'Settings',
    description: 'Here, you can customize your settings:',
    points: [
      '*Change password: *Update your password for enhanced security.',
      '*Add bank account: *Link your bank account to enable fiat deposits and withdrawals.',
    ],
  },
};
