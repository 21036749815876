import { computed, onUnmounted, ref, UnwrapRef, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export enum SocketsEvent {
  BalanceChanged = '.balance.changed',
  PayoutValidated = '.payout.validated',
  TransactionChanged = '.transaction.changed',
  PaymentIntentChanged = '.payment_intent.changed',
}

interface SocketsResponse<T> {
  data: T;
  socket: null;
}

export default function <T>(defaultEvent?: SocketsEvent) {
  const { state } = useStore();
  const route = useRoute();

  const userChannel = computed(() => {
    const userId = state.users.user?.id ?? '';

    return userId ? `users.${userId}` : '';
  });

  const eventData = ref<T | null>(null);

  // temporary

  const dontUpdate = computed(() => {
    const page = (route.query.page as string) ?? '1';
    const hasFilters = Object.keys(route.query)?.length > 1;

    return page !== '1' || hasFilters;
  });

  const shouldStack = computed(() => {
    const page = (route.query.page as string) ?? '1';

    return page === '1';
  });

  //

  function listenPrivateEvent(eventName: SocketsEvent) {
    try {
      window.Echo.private(userChannel.value).listen(
        eventName,
        (res: SocketsResponse<T>) =>
          (eventData.value = res.data as UnwrapRef<T>),
      );
    } catch (error) {
      return error;
    }
  }

  function stopListenPrivateEvent(eventName: SocketsEvent) {
    try {
      window.Echo.private(userChannel.value).stopListening(eventName);
    } catch (error) {
      return error;
    }
  }

  // auto sub and unsub
  if (defaultEvent) {
    watch(
      userChannel,
      (value) => {
        if (value) {
          listenPrivateEvent(defaultEvent);
        }
      },
      { immediate: true },
    );

    onUnmounted(() => stopListenPrivateEvent(defaultEvent));
  }

  return {
    eventData,
    dontUpdate,
    shouldStack,
    listenPrivateEvent,
    stopListenPrivateEvent,
  };
}
