export interface PaginationRequestParams {
  page?: number;
  per_page?: number;
  sort_column?: string;
  sort_type?: SortDirection;
}

export interface ColumnsRequestParams {
  id?: string;
  email?: string;
  username?: string;
  type?: string;
  name?: string;
  verification_status?: string;
  status?: string;
  statuses?: string[];
  cryptocurrencies?: string[];
  analytics_id?: string;
  'created_at[from]'?: string;
  'created_at[to]'?: string;
}

export type RequestParams = ColumnsRequestParams | PaginationRequestParams;

export interface Pagination {
  currentPage: number;
  page: number;
  lastPage: number;
  rowsNumber: number;
  rowsPerPage: number;
  sortBy?: null | string;
  descending?: boolean;
}

// Response

export interface PaginatedResponse<T> {
  data: T;
  links: PaginatedResponseLinks;
  meta: PaginatedResponseMeta;
}

export interface PaginatedResponseLinks {
  first: string;
  last: string;
  prev: null | string;
  next: null | string;
}

export interface PaginatedResponseMeta {
  current_page: number;
  from: null | number;
  last_page: null | number;
  links: PaginatedResponsePageLinks[];
  path: string;
  per_page: number;
  to: null | number;
  total: number;
}

export interface PaginatedResponsePageLinks {
  url: null | string;
  label: string;
  active: boolean;
}

export enum SortDirection {
  Descending = 'desc',
  Ascending = 'asc',
}
