import { VerificationResponse } from '@/app/types/api/resources/verification';
import { Currency } from '@/app/types/app/currency';

export enum Organizations {
  Organizations = '/api/organizations',
  ChangeDefaultCurrency = '/api/organizations/change-default-currency',
  AllowedCryptoCurrencies = '/api/organizations/allowed-cryptocurrencies',
}

export interface OrganizationsResponse {
  id: number;
  uid: string;
  name: string | null;
  vat_number: string | null;
  address: string | null;
  default_currency: Currency;
  verification: VerificationResponse;
  created_at: string;
}

export interface DefaultCurrencyPayload {
  default_currency_id: number;
}
