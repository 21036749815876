import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/documents',
    name: RouteName.Documents,
    component: () => import('@/views/ViewDocuments/ViewDocuments.vue'),
    redirect: { name: RouteName.VatInvoices },
    meta: { layout: Layout.Default },
    children: [
      {
        path: 'funding-instructions',
        name: RouteName.FundingInstructions,
        component: () =>
          import('@/views/ViewDocuments/ViewFundingInstructions.vue'),
      },
      {
        path: 'invoices',
        name: RouteName.VatInvoices,
        component: () => import('@/views/ViewDocuments/ViewVatInvoices.vue'),
      },
      {
        path: 'merchant-reports',
        name: RouteName.MerchantReports,
        component: () =>
          import('@/views/ViewDocuments/ViewMerchantReports.vue'),
      },
    ],
  },
];
