
import { computed, defineComponent } from 'vue';

import useOnboarding from '@/composables/onboarding/useOnboarding';

import AppOnboardingDesktop from '@C/common/AppOnboarding/AppOnboardingDesktop/AppOnboardingDesktop.vue';
import AppOnboardingIntro from '@C/common/AppOnboarding/AppOnboardingIntro.vue';
import AppOnboardingMobile from '@C/common/AppOnboarding/AppOnboardingMobile/AppOnboardingMobile.vue';

export default defineComponent({
  name: 'AppOnboarding',
  components: { AppOnboardingIntro, AppOnboardingMobile, AppOnboardingDesktop },
  setup() {
    const isMobileOrTablet = computed(
      () => window.Device.isMobile || window.Device.isTablet,
    );

    const { isOnboardingInProgress, isOnboardingLoading, handleOnboarding } =
      useOnboarding();

    return {
      isMobileOrTablet,
      isOnboardingInProgress,
      isOnboardingLoading,
      handleOnboarding,
    };
  },
});
