import { ExchangeCurrencyParams } from '@/app/types/api/resources/exchange';
import { CryptoCurrency, Currency } from '@/app/types/app/currency';

export enum Fees {
  Exchange = '/api/fees/exchange',
  WithdrawFiat = '/api/fees/fiat-withdrawal',
  DepositFiat = '/api/fees/fiat-deposit',
  Network = '/api/fees/network',
}

export type ExchangeFeeParams = ExchangeCurrencyParams;

export interface ExchangeFeeResponse {
  base_cryptocurrency: CryptoCurrency | null;
  base_currency: Currency | null;
  quote_cryptocurrency: CryptoCurrency | null;
  quote_currency: Currency | null;
  exchange_percentage: string;
  base_percentage: string;
  base_amount: string;
  quote_percentage: string;
  quote_amount: string;
}

export interface WithdrawFiatFeeParams {
  fiat_amount: number;
  currency_id: number;
}

export interface WithdrawFiatFeeResponse<TAmount = number> {
  amount: TAmount;
  currency: Currency;
  min_amount: number;
}

export interface DepositFiatFeeParams {
  currency: number;
}

export interface DepositFiatFeeResponse {
  max_operation_amount: string | null;
  fee_percentage: string | null;
  fee_amount: string | null;
  currency: Currency;
}

export interface NetworkFee {
  id: number;
  cryptocurrency: CryptoCurrency;
  amount: string;
  created_at: string;
}
