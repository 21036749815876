import { DictionaryResponse } from '@/app/types/api/resources/dictionaries';
import {
  Verification,
  VerificationResponse,
} from '@/app/types/api/resources/verification';

import client from '@/app/api/client';

export default {
  checkVerification(): Promise<DictionaryResponse<VerificationResponse>> {
    return client.get(Verification.CheckVerification);
  },
};
