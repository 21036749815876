import Store from '@/store';
import OrganizationsStoreService from '@/store/services/OrganizationsStoreService';

export default function (): Promise<void> {
  return new Promise((resolve, reject) => {
    const { state } = Store;

    if (state.organizations.currentOrganizationUID) {
      return resolve();
    }

    const orgService = new OrganizationsStoreService();

    return orgService
      .fetchOrganizations()
      .then(() => resolve())
      .catch(reject);
  });
}
