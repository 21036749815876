import { AxiosRequestHeaders, AxiosResponse } from 'axios';

import { DictionaryResponse } from '@/app/types/api/resources/dictionaries';
import {
  SignatureApi,
  SignatureExistenceResponse,
} from '@/app/types/api/resources/signature';

import client from '@/app/api/client';

export default {
  createSignature(
    headers?: AxiosRequestHeaders,
  ): Promise<AxiosResponse<string>> {
    return client.post(SignatureApi.CreateSignature, undefined, { headers });
  },

  checkSignatureExistence(): Promise<
    DictionaryResponse<SignatureExistenceResponse>
  > {
    return client.get(SignatureApi.CheckSignatureExistence);
  },
};
