import { RouteRecordRaw } from 'vue-router';

import addFunds from '@/router/resources/routes/add-funds';
import balances from '@/router/resources/routes/balances';
import business from '@/router/resources/routes/business';
import contactUs from '@/router/resources/routes/contact-us';
import dashboard from '@/router/resources/routes/dashboard';
import documents from '@/router/resources/routes/documents';
import payment from '@/router/resources/routes/payment';
import service from '@/router/resources/routes/service';
import settings from '@/router/resources/routes/settings';
import transactionsHistory from '@/router/resources/routes/transactions-history';
import withdraw from '@/router/resources/routes/withdraw';
import withdrawRequests from '@/router/resources/routes/withdraw-requests';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard',
  },

  ...dashboard,
  ...contactUs,
  ...transactionsHistory,
  ...addFunds,
  ...withdraw,
  ...balances,
  ...business,
  ...settings,
  ...payment,
  ...documents,
  ...withdrawRequests,
  ...service,
];

export default routes;
