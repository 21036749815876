import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/add-funds',
    name: RouteName.Topup,
    redirect: { name: RouteName.TopupFiat },
    meta: { layout: Layout.Default },
    children: [
      {
        path: 'crypto',
        name: RouteName.TopupCrypto,
        component: () => import('@/views/ViewTopup/ViewTopupCrypto.vue'),
      },
      {
        path: 'fiat',
        name: RouteName.TopupFiat,
        component: () => import('@/views/ViewTopup/ViewTopupFiat.vue'),
      },
    ],
  },
];
