import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

import accessGuard from '@/router/guards/accessGuard';
import maintenanceGuard from '@/router/guards/maintenanceGuard';
import signatureGuard from '@/router/guards/signatureGuard';

export default [
  // blocked
  {
    path: '/signature',
    name: RouteName.Signature,
    component: () => import('@/views/ServiceViews/ViewSignature.vue'),
    meta: {
      layout: Layout.Empty,
      canLogout: true,
    },
    beforeEnter: signatureGuard,
  },
  // blocked
  {
    path: '/access-restricted',
    name: RouteName.AccessRestricted,
    component: () => import('@/views/ServiceViews/ViewUserBlocked.vue'),
    meta: {
      layout: Layout.Empty,
      canLogout: true,
    },
    beforeEnter: accessGuard,
  },
  // maintenance
  {
    name: RouteName.Maintenance,
    path: '/maintenance',
    component: () => import('@/views/ServiceViews/ViewMaintenance.vue'),
    meta: { layout: Layout.Empty },
    beforeEnter: maintenanceGuard,
  },
  // 404
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
  {
    path: '/404',
    name: RouteName.NotFound,
    component: () => import('@/views/ServiceViews/ViewNotFound.vue'),
    meta: {
      layout: Layout.Empty,
      canLogout: true,
    },
  },
];
