import { Path } from '@intlify/core-base';

export type I18n = (
  key: Path | number,
  plural?: string | number | Record<string, string | number>,
) => string;

export enum Locales {
  En = 'en',
  EnUs = 'en-US',
  Ru = 'ru',
}
