import { StoreCommit } from '@/app/types/store/store';

import Store from '@/store';

import usersApi from '@/app/api/resources/users';

export default class {
  fetchMe() {
    return usersApi
      .fetchMe()
      .then(({ data }) => Store.commit(StoreCommit.SetUser, data.user));
  }

  completeOnboarding() {
    return usersApi
      .completeOnboarding()
      .then(({ data }) => Store.commit(StoreCommit.SetUser, data.data));
  }
}
