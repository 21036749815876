import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/payment/single',
    name: RouteName.PaymentSingle,
    component: () => import('@/views/ViewSinglePayment.vue'),
    meta: {
      layout: Layout.Default,
    },
  },
];
