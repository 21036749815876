import { AxiosError, AxiosRequestConfig } from 'axios';

import {
  ErrorResponse,
  HttpStatus,
  RequestHeaders,
} from '@/app/types/api/config/config';
import { StoreCommit } from '@/app/types/store/store';

import cookies from '@/plugins/cookies';
import i18n from '@/plugins/i18n';
import Store from '@/store';

import env from '@/app/env/env';
import getUniqId from '@/app/helpers/getUniqId';
import logoutAndRedirect from '@/app/helpers/logoutAndRedirect';

import router from '@/router';

const AppProjectName = 'INXY-APP';

// getToken

export function getToken() {
  const token = cookies.get(env.cookieName);

  return token ? 'Bearer ' + token : '';
}

// setRequestHeaders

export function setRequestHeaders(config: AxiosRequestConfig) {
  // set auth header
  const token = config?.headers?.Authorization;
  const isTokenExpired = token !== getToken();

  if (config.headers && (!token || isTokenExpired)) {
    config.headers['Authorization'] = getToken();
  }

  // set org uid header
  const orgId = Store.state.organizations.currentOrganizationUID;

  if (config.headers && orgId) {
    config.headers[RequestHeaders.OrganizationId] = orgId;
  }

  // set path and page-id headers
  const path = router.currentRoute.value.path;

  if (config.headers && path) {
    config.headers[RequestHeaders.PageRequestId] = getUniqId(path);
    config.headers[RequestHeaders.PagePath] = path;
    config.headers[RequestHeaders.ProjectName] = AppProjectName;
  }

  return config;
}

// handleAuthError

export function handleAuthError(error: AxiosError<ErrorResponse>) {
  if (error?.response?.status === HttpStatus.Unauthorized) {
    logoutAndRedirect();
  }
}

// getErrorMessages

export function getErrorMessages(error: AxiosError<ErrorResponse>): string[] {
  const message =
    error.response?.data?.message ??
    i18n.global.t('errors.standardServerError');
  const errors = error.response?.data?.errors;

  switch (error?.response?.status) {
    case HttpStatus.PaymentRequired:
      return [i18n.global.t('errors.paymentFailed')];
    case HttpStatus.UnprocessableEntity:
      return errors ? Object.values(errors).flat() : [message];
    case HttpStatus.ServiceUnavailable:
      return [i18n.global.t('errors.serviceUnavailable')];
    default:
      return [message];
  }
}

// setMaintenanceStatus

export function setMaintenanceStatus(responseStatus: HttpStatus | undefined) {
  if (responseStatus === HttpStatus.ServiceUnavailable) {
    Store.commit(StoreCommit.SetServerStatus, responseStatus);
  }
}
