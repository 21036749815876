import { UserStatus } from '@/app/types/api/resources/users';
import {
  UsersGetters,
  UsersMutations,
  UsersState,
} from '@/app/types/store/modules/users';

const state = (): UsersState => ({
  user: null,
});

const mutations: UsersMutations = {
  setUser(state, user) {
    state.user = user;
  },
};

const getters: UsersGetters = {
  hasUser: (state) => !!state.user,

  userNickName: (state) => state.user?.username ?? '',

  userEmail: (state) => state.user?.email ?? '',

  isEmailVerified: (state) => !!state.user?.email_verified_at,

  isUserBlocked: (state) => state.user?.status === UserStatus.Blocked,

  isOnboardingCompleted: (state) => !!state.user?.onboarding_completed_at,
};

export default {
  namespaced: true,

  state,
  mutations,
  getters,
};
