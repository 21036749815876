import { NavigationGuard } from 'vue-router';

import { RouteName } from '@/app/types/app/routes';

import Store from '@/store';

const accessGuard: NavigationGuard = (to, from, next) => {
  const isBlocked = Store.getters['users/isUserBlocked'];

  if (!isBlocked) {
    next({ name: RouteName.Dashboard });
  }

  next();
};

export default accessGuard;
