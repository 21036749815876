import { GtmEvent } from '@/app/types/app/events';

import cookies from '@/plugins/cookies';

import env from '@/app/env/env';
import redirect from '@/app/helpers/redirect';

const redirectDataCookie = 'inxy-redirect-path';

function handleRedirectPath() {
  const redirectFullPath = `${window.location.pathname}${window.location.search}`;

  cookies.set(redirectDataCookie, redirectFullPath, 5 * 60, '/', env.host);
}

export default function (isLogout?: boolean) {
  if (!isLogout) {
    handleRedirectPath();
  }

  cookies.remove(env.cookieName, '/', env.host);

  window.dataLayer?.push({ event: GtmEvent.Logout });

  redirect('authURL');

  return;
}
