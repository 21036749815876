export default {
  twoFactorAction: 'Confirm withdraw',
  requestHistory: 'Request history',
  crypto: {
    totalAmount: 'Total amount',
    networkFee: 'Network fee',
    balance: 'Balance',
    withdrawFiatLink: 'Withdraw fiat',
    maxAmount: 'Max',
    tips: [
      { title: 'Initiate a withdrawal', text: 'Choose crypto and blockchain' },
      {
        title: 'Specify address',
        text: 'Paste the wallet address in the selected blockchain',
      },
      {
        title: 'Two-step verification',
        text: 'Enter the security code that was sent to you via email',
      },
      {
        title: 'Withdrawal successful',
        text: "Withdrawal successfully sent to recipient's address",
      },
    ],
  },
  fiat: {
    withdrawCryptoLink: 'Withdraw crypto',
    changeAccount: 'Change account',
    withdrawSuccess: 'Withdrawal request has been successfully made',
    tips: [
      {
        title: 'Enter amount',
        text: 'Specify the amount of fiat you wish to withdraw.',
      },
      {
        title: 'Select account',
        text: 'Select the bank account where you want to receive the withdrawn funds.',
      },
      {
        title: 'Verify details',
        text: 'Review and confirm the withdrawal transaction details.',
      },
      {
        title: 'Receive funds',
        text: 'The funds will be sent to your selected bank account within 1-3 business days.',
      },
    ],
    fields: {
      accountNum: 'Account Nr.:',
      transferMethod: 'Transaction method:',
      bankTransfer: 'Bank transfer',
      serviceFee: 'Service fee:',
      totalReceive: 'You will receive:',
      minWithdrawal: 'Min withdrawal:',
    },
    withdrawNote:
      'Withdrawing fiat by bank transfer incurs a flat rate fee of {fee}. The request processing time takes 1-2 business days. The bank transfer speed depends on the policies of the receiving bank, and funds will typically arrive in your selected bank account within 1-3 business days after the processing of your request is complete.',
  },
  alert: {
    prepend: 'Sorry,',
    bold: 'we are unable to process automated withdrawal request ',
    linkPrepend:
      ' at this time due to recent changes in our legal compliance procedures. Please note that this is a temporary restriction and we are working hard to update our systems to ensure a smooth and compliant experience for our customers. If you need to withdraw funds urgently, please ',
    link: 'contact our support team',
    append: ' who will be happy to assist you.',
  },
  withdrawRestricted: {
    prepend:
      'Sorry, automated withdrawals are currently unavailable due to legal compliance updates. For urgent withdrawals, please',
    link: 'contact support',
    append: 'for assistance.',
  },
  serverError: {
    prepend:
      'Your request for withdrawal cannot be processed at this time. Please try again later. For urgent withdrawals, please',
    link: 'contact support',
    append: 'for assistance.',
  },
};
