import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  // payments
  {
    path: '/business/payments',
    name: RouteName.BusinessPayments,
    redirect: { name: RouteName.BusinessPaymentsInvoices },
    component: () =>
      import(
        '@/views/ViewBusiness/ViewBusinessPayments/ViewBusinessPayments.vue'
      ),
    children: [
      {
        path: 'invoices',
        name: RouteName.BusinessPaymentsInvoices,
        component: () =>
          import(
            '@/views/ViewBusiness/ViewBusinessPayments/ViewBusinessPaymentsInvoices.vue'
          ),
        children: [
          {
            path: ':invoice_id',
            name: RouteName.BusinessPaymentsInvoiceDetails,
            component: () =>
              import(
                '@/views/ViewBusiness/ViewBusinessPayments/ViewBusinessPaymentsInvoiceDetails.vue'
              ),
          },
        ],
      },
      {
        path: 'donations',
        name: RouteName.BusinessPaymentsDonations,
        component: () =>
          import(
            '@/views/ViewBusiness/ViewBusinessPayments/ViewBusinessPaymentsDonations.vue'
          ),
      },
    ],
    meta: {
      layout: Layout.Default,
    },
  },
  // payouts
  {
    path: '/business/payouts',
    name: RouteName.MassPayouts,
    component: () =>
      import(
        '@/views/ViewBusiness/ViewBusinessPayouts/ViewBusinessPayouts.vue'
      ),
    children: [
      {
        path: ':payouts_id',
        name: RouteName.MassPayoutsDetails,
        component: () =>
          import(
            '@/views/ViewBusiness/ViewBusinessPayouts/ViewBusinessPayoutsDetails.vue'
          ),
      },
    ],
    meta: {
      layout: Layout.Default,
    },
  },
];
