import Store from '@/store';
import OrganizationsStoreService from '@/store/services/OrganizationsStoreService';

const orgService = new OrganizationsStoreService();

export default function (): void {
  const { state } = Store;

  // TODO: could be always 0 - fetching all the time
  if (state.organizations.allowedCryptoCurrencies.length === 0) {
    orgService.fetchAllowedCryptoCurrencies();
  }
}
