import { NavigationGuard } from 'vue-router';

import { RouteName } from '@/app/types/app/routes';

import Store from '@/store';

const signatureGuard: NavigationGuard = (to, from, next) => {
  const hasSignature = Store.state.access.hasSignature;

  if (hasSignature) {
    next({ name: RouteName.Dashboard });
  }

  next();
};

export default signatureGuard;
