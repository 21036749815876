import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/contact-us',
    name: RouteName.ContactUs,
    component: () => import('@/views/ViewFeedback.vue'),
    meta: { layout: Layout.Default },
  },
];
