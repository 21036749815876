import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import getUniqId from '@/app/helpers/getUniqId';

const openedToggleId = ref<string>('');

export default function () {
  const route = useRoute();

  const toggleId = getUniqId();

  const isToggleActive = ref<boolean>(false);
  const isSingleToggleActive = computed<boolean>(
    () => openedToggleId.value === toggleId,
  );

  function switchToggle() {
    isToggleActive.value = !isToggleActive.value;

    openedToggleId.value = isSingleToggleActive.value ? '' : toggleId;
  }

  function turnOffToggle() {
    isToggleActive.value = false;

    openedToggleId.value = '';
  }

  watch(route, turnOffToggle);

  return { isToggleActive, isSingleToggleActive, switchToggle, turnOffToggle };
}
