import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppOnboardingIntro = _resolveComponent("AppOnboardingIntro")!
  const _component_AppOnboardingMobile = _resolveComponent("AppOnboardingMobile")!
  const _component_AppOnboardingDesktop = _resolveComponent("AppOnboardingDesktop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isOnboardingInProgress)
      ? (_openBlock(), _createBlock(_component_AppOnboardingIntro, {
          key: 0,
          onProceed: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOnboardingInProgress = true)),
          onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleOnboarding(true)))
        }))
      : _createCommentVNode("", true),
    (_ctx.isOnboardingInProgress)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.isMobileOrTablet)
            ? (_openBlock(), _createBlock(_component_AppOnboardingMobile, {
                key: 0,
                "is-loading": _ctx.isOnboardingLoading,
                onSubmit: _ctx.handleOnboarding,
                onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleOnboarding(true)))
              }, null, 8, ["is-loading", "onSubmit"]))
            : (_openBlock(), _createBlock(_component_AppOnboardingDesktop, {
                key: 1,
                "is-loading": _ctx.isOnboardingLoading,
                onSubmit: _ctx.handleOnboarding,
                onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleOnboarding(true)))
              }, null, 8, ["is-loading", "onSubmit"]))
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}