export default {
  singlePaymentTitle: 'New one-time payment',
  twoFactorAction: 'Confirm payment',
  form: {
    recipientInfo: 'Recipient info',
    walletAddress: 'Wallet address',
    walletAddressLabel: 'External wallet',
    inxyAccount: 'INXY account',
    inxyAccountPlaceholder: 'IXX0000000000000',
    paymentName: 'Payment name',
    alert: {
      blockchain:
        'Blockchain must match wallet address, otherwise the recipient won’t receive this payment.',
    },
  },
};
