import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e2f9c72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-slides-count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalSlides, (index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: _normalizeClass(["app-slides-count__item", { 'app-slides-count__item_active': index === _ctx.activeSlide }])
      }, null, 2))
    }), 128))
  ]))
}