import {
  BalancesActions,
  BalancesMutations,
  BalancesState,
  CryptoBalanceMap,
  FiatBalanceMap,
} from '@/app/types/store/modules/balances';

import BalancesFormat from '@/app/formatters/BalancesFormat';

const state = (): BalancesState => ({
  cryptoBalances: {},
  fiatBalances: {},
});

const mutations: BalancesMutations = {
  setCryptoBalances(state, balances) {
    state.cryptoBalances = balances;
  },

  setFiatBalances(state, balances) {
    state.fiatBalances = balances;
  },
};

const actions: BalancesActions = {
  createBalancesMap({ commit }, data) {
    const cryptoMap: CryptoBalanceMap = {};
    const fiatMap: FiatBalanceMap = {};

    data.forEach((account) => {
      cryptoMap[account.id] = BalancesFormat.cryptoToIntArr(
        account.balances.crypto,
      );
      fiatMap[account.id] = BalancesFormat.fiatToInt(account.balances.fiat);
    });

    commit('setCryptoBalances', cryptoMap);
    commit('setFiatBalances', fiatMap);
  },
  updateCryptoBalancesMapManual({ state, commit }, balance) {
    const accountId = balance.account_id;

    const updatedAccBalances = state.cryptoBalances[accountId].map((el) =>
      el.cryptocurrency.id === balance.cryptocurrency.id
        ? BalancesFormat.cryptoToInt(balance)
        : el,
    );
    const updated = {
      ...state.cryptoBalances,
      [accountId]: updatedAccBalances,
    };

    commit('setCryptoBalances', updated);
  },
  updateCryptoBalancesMap({ state, commit }, { accountId, balances }) {
    const updated = { ...state.cryptoBalances, [accountId]: balances };

    commit('setCryptoBalances', updated);
  },
  updateFiatBalancesMap({ state, commit }, { accountId, balances }) {
    const updated = { ...state.fiatBalances, [accountId]: balances };

    commit('setFiatBalances', updated);
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  actions,
};
