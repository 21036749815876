import { DictionaryResponse } from '@/app/types/api/resources/dictionaries';
import {
  DepositFiatFeeParams,
  DepositFiatFeeResponse,
  ExchangeFeeParams,
  ExchangeFeeResponse,
  Fees,
  NetworkFee,
  WithdrawFiatFeeParams,
  WithdrawFiatFeeResponse,
} from '@/app/types/api/resources/fees';

import client from '@/app/api/client';

export default {
  fetchExchangeFee(
    params: ExchangeFeeParams,
  ): Promise<DictionaryResponse<ExchangeFeeResponse>> {
    return client.get(Fees.Exchange, { params });
  },

  fetchWithdrawFiatFee(
    params: WithdrawFiatFeeParams,
  ): Promise<DictionaryResponse<WithdrawFiatFeeResponse<string>>> {
    return client.get(Fees.WithdrawFiat, { params });
  },

  fetchDepositFiatFee(
    params: DepositFiatFeeParams,
  ): Promise<DictionaryResponse<DepositFiatFeeResponse[]>> {
    return client.get(Fees.DepositFiat, { params });
  },

  fetchNetworkFees(): Promise<DictionaryResponse<NetworkFee[]>> {
    return client.get(Fees.Network);
  },
};
