import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/transactions-history',
    name: RouteName.TransactionsHistory,
    component: () =>
      import('@/views/ViewTransactionsHistory/ViewTransactionsHistory.vue'),
    redirect: { name: RouteName.TransactionsHistoryCrypto },
    meta: {
      layout: Layout.Default,
    },
    children: [
      {
        path: 'crypto',
        name: RouteName.TransactionsHistoryCrypto,
        component: () =>
          import(
            '@/views/ViewTransactionsHistory/ViewTransactionsHistoryCrypto.vue'
          ),
        children: [
          {
            path: ':type/:transaction_id',
            name: RouteName.CryptoTransactionDetails,
            component: () =>
              import(
                '@/views/ViewTransactionsHistory/ViewCryptoTransactionDetails.vue'
              ),
          },
        ],
      },
      {
        path: 'fiat',
        name: RouteName.TransactionsHistoryFiat,
        component: () =>
          import(
            '@/views/ViewTransactionsHistory/ViewTransactionsHistoryFiat.vue'
          ),
        children: [
          {
            path: ':account_id/:transaction_id',
            name: RouteName.FiatTransactionDetails,
            component: () =>
              import(
                '@/views/ViewTransactionsHistory/ViewFiatTransactionDetails.vue'
              ),
          },
        ],
      },
    ],
  },
];
