import Notify from 'quasar/src/plugins/Notify.js';import Screen from 'quasar/src/plugins/Screen.js';;

export default {
  config: {
    brand: {
      negative: 'var(--clr-error)',
      positive: 'var(--clr-success)',
      warning: 'var(--clr-warning)',
      primary: 'var(--clr-yellow-600)',
      accent: 'var(--clr-amber-400)',
    },
    notify: {
      position: 'top',
      classes: 'app-notify',
    },
  },
  plugins: {
    Notify,
    Screen,
  },
};
