import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { useCookies } from 'vue3-cookies';

import env from '@/app/env/env';

export default function () {
  try {
    const { cookies } = useCookies();
    const token = cookies.get(env.cookieName);

    window.Pusher = Pusher;

    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: env.echoPublicKey,
      cluster: 'eu',
      wsHost: 'realtime-pusher.ably.io',
      wsPort: 443,
      authEndpoint: `${env.baseURL}/broadcasting/auth`,
      auth: {
        headers: { Authorization: 'Bearer ' + token },
      },
      disableStats: true,
      encrypted: true,
    });
  } catch (error: any) {
    return error;
  }
}
