import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  // profile settings
  {
    path: '/settings',
    component: () =>
      import(
        '@/views/ViewSettings/ViewSettingsProfile/ViewSettingsProfile.vue'
      ),
    redirect: '/settings/profile',
    meta: {
      layout: Layout.Default,
    },
    children: [
      {
        path: 'profile',
        name: RouteName.ProfileSettings,
        component: () =>
          import(
            '@/views/ViewSettings/ViewSettingsProfile/ViewSettingsProfileGeneral.vue'
          ),
      },
      {
        path: 'banking',
        name: RouteName.BankingSettings,
        component: () =>
          import(
            '@/views/ViewSettings/ViewSettingsProfile/ViewSettingsProfileBanking.vue'
          ),
      },
    ],
  },
  // merchant settings
  {
    path: '/settings/merchant',
    component: () =>
      import(
        '@/views/ViewSettings/ViewMerchantSettings/ViewMerchantSettings.vue'
      ),
    redirect: '/settings/merchant/public-profile',
    meta: {
      layout: Layout.Default,
    },
    children: [
      {
        path: 'public-profile',
        name: RouteName.MerchantSettingsProfile,
        component: () =>
          import(
            '@/views/ViewSettings/ViewMerchantSettings/ViewMerchantSettingsProfile.vue'
          ),
      },
      {
        path: 'cryptocurrencies',
        name: RouteName.MerchantSettingsCurrencies,
        component: () =>
          import(
            '@/views/ViewSettings/ViewMerchantSettings/ViewMerchantSettingsCurrencies.vue'
          ),
      },
      {
        path: 'account',
        name: RouteName.MerchantSettingsAccount,
        component: () =>
          import(
            '@/views/ViewSettings/ViewMerchantSettings/ViewMerchantSettingsAccount.vue'
          ),
      },
      {
        path: 'paylink',
        name: RouteName.MerchantSettingsPaylink,
        component: () =>
          import(
            '@/views/ViewSettings/ViewMerchantSettings/ViewMerchantSettingsPaylink.vue'
          ),
      },
      {
        path: 'donations',
        name: RouteName.MerchantSettingsDonations,
        component: () =>
          import(
            '@/views/ViewSettings/ViewMerchantSettings/ViewMerchantSettingsDonate.vue'
          ),
      },
      {
        path: 'gateway-api',
        name: RouteName.MerchantSettingsGatewayApi,
        component: () =>
          import(
            '@/views/ViewSettings/ViewMerchantSettings/ViewMerchantSettingsGatewayApi.vue'
          ),
      },
      {
        path: 'notifications',
        name: RouteName.MerchantSettingsNotifications,
        component: () =>
          import(
            '@/views/ViewSettings/ViewMerchantSettings/ViewMerchantSettingsNotifications.vue'
          ),
      },
    ],
  },
  // payouts api
  {
    path: '/settings/payouts',
    component: () =>
      import(
        '@/views/ViewSettings/ViewPayoutsSettings/ViewPayoutsSettings.vue'
      ),
    redirect: '/settings/payouts/api',
    meta: {
      layout: Layout.Default,
    },
    children: [
      {
        path: 'api',
        name: RouteName.PayoutsSettingsApi,
        component: () =>
          import(
            '@/views/ViewSettings/ViewPayoutsSettings/ViewPayoutsSettingsApi.vue'
          ),
      },
      {
        path: 'account',
        name: RouteName.PayoutsSettingsAccount,
        component: () =>
          import(
            '@/views/ViewSettings/ViewPayoutsSettings/ViewPayoutsSettingsAccount.vue'
          ),
      },
    ],
  },
  // webhooks
  {
    path: '/settings/webhooks',
    redirect: '/settings/webhooks/incoming',
    meta: {
      layout: Layout.Default,
    },
    children: [
      {
        path: 'incoming',
        name: RouteName.GatewayWebhooks,
        component: () =>
          import('@/views/ViewSettings/ViewWebhooks/ViewWebhooksGateway.vue'),
      },
      {
        path: 'outgoing',
        name: RouteName.PayersWebhooks,
        component: () =>
          import('@/views/ViewSettings/ViewWebhooks/ViewWebhooksPayers.vue'),
      },
    ],
  },
];
