
import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

import IconApiSettings from '@/components/ui/icons/MainSidebar/IconApiSettings.vue';
import IconApiSettingsActive from '@/components/ui/icons/MainSidebar/IconApiSettingsActive.vue';
import IconBalances from '@/components/ui/icons/MainSidebar/IconBalances.vue';
import IconBalancesActive from '@/components/ui/icons/MainSidebar/IconBalancesActive.vue';
import IconBusinessPayments from '@/components/ui/icons/MainSidebar/IconBusinessPayments.vue';
import IconBusinessPaymentsActive from '@/components/ui/icons/MainSidebar/IconBusinessPaymentsActive.vue';
import IconDashboard from '@/components/ui/icons/MainSidebar/IconDashboard.vue';
import IconDashboardActive from '@/components/ui/icons/MainSidebar/IconDashboardActive.vue';
import IconDocuments from '@/components/ui/icons/MainSidebar/IconDocuments.vue';
import IconDocumentsActive from '@/components/ui/icons/MainSidebar/IconDocumentsActive.vue';
import IconMassPay from '@/components/ui/icons/MainSidebar/IconMassPay.vue';
import IconMassPayActive from '@/components/ui/icons/MainSidebar/IconMassPayActive.vue';
import IconMerchantSettings from '@/components/ui/icons/MainSidebar/IconMerchantSettings.vue';
import IconMerchantSettingsActive from '@/components/ui/icons/MainSidebar/IconMerchantSettingsActive.vue';
import IconSinglePayment from '@/components/ui/icons/MainSidebar/IconSinglePayment.vue';
import IconSinglePaymentActive from '@/components/ui/icons/MainSidebar/IconSinglePaymentActive.vue';
import IconTransactionsHistory from '@/components/ui/icons/MainSidebar/IconTransactionsHistory.vue';
import IconTransactionsHistoryActive from '@/components/ui/icons/MainSidebar/IconTransactionsHistoryActive.vue';

export default defineComponent({
  name: 'MainSidebarItem',
  components: {
    IconDashboard,
    IconDashboardActive,
    IconTransactionsHistory,
    IconTransactionsHistoryActive,
    IconApiSettings,
    IconApiSettingsActive,
    IconBusinessPayments,
    IconBusinessPaymentsActive,
    IconBalances,
    IconBalancesActive,
    IconMassPay,
    IconMassPayActive,
    IconSinglePayment,
    IconSinglePaymentActive,
    IconMerchantSettings,
    IconMerchantSettingsActive,
    IconDocuments,
    IconDocumentsActive,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    route: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      required: true,
    },
    iconComponentName: {
      type: String,
      required: true,
    },
    iconActiveComponentName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    function sidebarIconComponentName(isActive: boolean): string {
      return isActive ? props.iconActiveComponentName : props.iconComponentName;
    }

    return { sidebarIconComponentName };
  },
});
