import { QNotifyCreateOptions } from 'quasar/dist/types/index';

import { HttpStatus, InternalStatus } from '@/app/types/api/config/config';

export interface ClientResponseErrorParams extends Error {
  isTwoFactorAuth: boolean;
  notify: QNotifyCreateOptions | null;
  messages: string[];
  status?: number;
  errors: Record<string, any> | null;
}

export default class ClientResponseError extends Error {
  isTwoFactorAuth: boolean;
  notify: QNotifyCreateOptions | null;
  messages: string[];
  name: string;
  status?: number;
  errors: Record<string, any> | null;

  constructor({
    name,
    isTwoFactorAuth,
    notify,
    message,
    messages,
    status,
    errors,
  }: ClientResponseErrorParams) {
    super(message);

    this.name = name || 'Server response error';
    this.isTwoFactorAuth = isTwoFactorAuth;
    this.notify = notify;
    this.messages = messages;
    this.status = status;
    this.errors = errors;
  }

  static createSimpleError(
    name: string,
    status?: HttpStatus | InternalStatus,
    message?: string,
  ) {
    return new ClientResponseError({
      message: message ?? name,
      status,
      notify: null,
      errors: [],
      name,
      isTwoFactorAuth: false,
      messages: [],
    });
  }
}
