export enum UsersApi {
  Me = '/api/users/me',
  Balances = '/api/balances',
  ChangePassword = '/api/users/change-password',
  ValidatePassChange = '/api/users/change-password/validate',
  CompleteOnboarding = '/api/users/onboarding/completed',
}

export enum UserStatus {
  Active = 'active',
  Blocked = 'blocked',
}

export interface User {
  id: number;
  email: string;
  username: string;
  status: UserStatus;
  email_verified_at: string | null;
  onboarding_completed_at: string | null;
  analytics_id: string;
}

export interface UsersMeResponse {
  user: User;
}

export interface UserChangePassword {
  old_password: string;
  password: string;
  password_confirmation: string;
}

export enum OnboardingStep {
  Balances = 'balances',
  ReceiveCrypto = 'receive-crypto',
  SendCrypto = 'send-crypto',
  Settings = 'settings',
}
