export default {
  navigation: {
    cryptoTransactions: 'Crypto transactions',
    fiatTransactions: 'Fiat transactions',
    showAll: 'See all transactions',
  },
  emptyMessage: 'No transactions yet',
  newTransactionsWillAppearHere: 'All new transactions will appear here.',
  transactionDetails: 'Transaction details',
  filterNotFound: {
    noResults: 'There are no results with these filter settings.',
    tryOther: 'Try other filter settings.',
  },
  fee: 'Fee',
  fees: 'Fees',
  status: {
    pending: '@:common.statuses.pending',
    finished: '@:common.statuses.completed',
    rejected: '@:common.statuses.rejected',
  },
  type: {
    incoming: 'Incoming',
    outgoing: 'Outgoing',
    all: 'All',
  },
};
