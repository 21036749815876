import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/requests',
    name: RouteName.Requests,
    redirect: { name: RouteName.RequestsCryptoWithdraw },
    component: () =>
      import('@/views/ViewRequestsHistory/ViewRequestsHistory.vue'),
    meta: { layout: Layout.Default },
    children: [
      {
        path: 'crypto',
        name: RouteName.RequestsCryptoWithdraw,
        component: () =>
          import('@/views/ViewRequestsHistory/ViewRequestsHistoryCrypto.vue'),
      },
      {
        path: 'bank',
        name: RouteName.RequestsFiatWithdraw,
        component: () =>
          import('@/views/ViewRequestsHistory/ViewRequestsHistoryFiat.vue'),
      },
      {
        path: 'exchange',
        name: RouteName.RequestsExchange,
        component: () =>
          import('@/views/ViewRequestsHistory/ViewRequestsHistoryExchange.vue'),
      },
    ],
  },
];
