import { RouteLocationNormalized } from 'vue-router';

import { InternalStatus } from '@/app/types/api/config/config';

import Store from '@/store';
import AccessStoreService from '@/store/services/AccessStoreService';

import ClientResponseError from '@/app/errors/ClientResponseError';

export default function (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const { state } = Store;

    if (to.name === from.name) {
      return resolve();
    }

    if (state.access.hasSignature) {
      return resolve();
    }

    function handleNoSignature() {
      if (!state.access.hasSignature) {
        return reject(
          ClientResponseError.createSimpleError(
            'No signature',
            InternalStatus.NoSignature,
          ),
        );
      }
    }

    const accessService = new AccessStoreService();

    return accessService
      .checkSignatureExistence()
      .then(() => {
        handleNoSignature();

        return resolve();
      })
      .catch(handleNoSignature);
  });
}
