export default {
  header: {
    payNow: 'Pay now',
    feedbackButton: {
      need: 'Need',
      support: 'Support?',
    },
  },
  advancedTools: {
    title: 'Advanced Tools',
    basic: 'Basic',
    paymentGateway: 'Payment gateway',
    donationLink: '@:common.pagesTitle.donations',
    paymentLink: '@:common.pagesTitle.paylinks',
    massPayouts: '@:common.pagesTitle.massPayouts',
  },
};
