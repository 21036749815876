import { CryptoBalance, FiatBalance } from '@/app/types/api/resources/balances';
import { CryptoCurrency } from '@/app/types/app/currency';

export default {
  cryptoToInt(
    balance: CryptoBalance<CryptoCurrency, string>,
  ): CryptoBalance<CryptoCurrency, number> {
    return {
      ...balance,
      fiatAmount: parseFloat(balance.fiatAmount),
      amount: parseFloat(balance.amount),
      pendingReceived: parseFloat(balance.pendingReceived),
    };
  },

  cryptoToIntArr(
    balances: CryptoBalance<CryptoCurrency, string>[],
  ): CryptoBalance<CryptoCurrency, number>[] {
    return balances.map((balance) => this.cryptoToInt(balance));
  },

  fiatToInt(balances: FiatBalance<string>[]): FiatBalance<number>[] {
    return balances.map((balance) => ({
      ...balance,
      amount: parseFloat(balance.amount),
    }));
  },
};
