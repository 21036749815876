import { AxiosPaginatedResponse } from '@/app/types/api/config/config';
import {
  OrgAccountsApi,
  OrgAccountsRequestBody,
  OrgAccountsResponse,
  OrgAccountsWithBalancesResponse,
  TransferFiatRequestBody,
} from '@/app/types/api/resources/accounts';
import { DictionaryResponse } from '@/app/types/api/resources/dictionaries';

import client from '@/app/api/client';
import getUrlWithId from '@/app/helpers/getUrlWithId';

export default {
  fetchOrgAccounts(): Promise<AxiosPaginatedResponse<OrgAccountsResponse[]>> {
    return client.get(OrgAccountsApi.Accounts);
  },

  fetchOrgAccountsWithBalances(): Promise<
    DictionaryResponse<OrgAccountsWithBalancesResponse[]>
  > {
    return client.get(OrgAccountsApi.AccountWithBalances);
  },

  fetchOrgAccountById(
    id: number,
  ): Promise<DictionaryResponse<OrgAccountsResponse>> {
    return client.get(getUrlWithId(OrgAccountsApi.AccountById, id));
  },

  createOrgAccount(
    data: OrgAccountsRequestBody,
  ): Promise<DictionaryResponse<OrgAccountsResponse>> {
    return client.post(OrgAccountsApi.Accounts, data);
  },

  updateOrgAccount(
    id: number,
    data: OrgAccountsRequestBody,
  ): Promise<DictionaryResponse<OrgAccountsResponse>> {
    const url = getUrlWithId(OrgAccountsApi.AccountById, id);

    return client.put(url, data);
  },

  // todo response
  transferFiat(
    data: TransferFiatRequestBody,
  ): Promise<DictionaryResponse<OrgAccountsResponse>> {
    return client.post(OrgAccountsApi.TransferFiat, data);
  },
};
