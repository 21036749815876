import QS from 'qs';

import {
  DictionariesApi,
  DictionaryResponse,
  RequestSorting,
} from '@/app/types/api/resources/dictionaries';
import {
  CryptoCurrency,
  CryptoCurrencySortFields,
  Currency,
  ExchangeRate,
} from '@/app/types/app/currency';

import client from '@/app/api/client';

export default {
  fetchCurrencies(): Promise<DictionaryResponse<Currency[]>> {
    return client.get(DictionariesApi.Currencies);
  },

  fetchCryptoCurrencies(
    params?: RequestSorting<CryptoCurrencySortFields>,
  ): Promise<DictionaryResponse<CryptoCurrency[]>> {
    return client.get(DictionariesApi.CryptoCurrencies, {
      params,
      paramsSerializer: QS.stringify,
    });
  },

  fetchExchangeRates(): Promise<DictionaryResponse<ExchangeRate<string>[]>> {
    return client.get(DictionariesApi.ExchangeRates);
  },
};
