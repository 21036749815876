
import { defineComponent } from 'vue';

import ButtonLoader from '@/components/ui/loaders/ButtonLoader.vue';

export default defineComponent({
  name: 'AppButton',
  components: { ButtonLoader },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
});
