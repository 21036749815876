import { StoreCommit } from '@/app/types/store/store';

import Store from '@/store';

import maintenance from '@/app/api/resources/maintenance';
import signature from '@/app/api/resources/signature';

export default class {
  getServerStatus() {
    return maintenance.getServerStatus().then((res) => {
      Store.commit(StoreCommit.SetServerStatus, res.status);

      return Promise.resolve();
    });
  }

  checkSignatureExistence() {
    return signature.checkSignatureExistence().then(({ data }) => {
      Store.commit(StoreCommit.SetSignatureExistence, data.data.exist);

      return Promise.resolve();
    });
  }
}
