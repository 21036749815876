import { HttpStatus } from '@/app/types/api/config/config';

import Store from '@/store';
import UserStoreService from '@/store/services/UserStoreService';

import ClientResponseError from '@/app/errors/ClientResponseError';
import redirect from '@/app/helpers/redirect';

export default function (): Promise<void> {
  return new Promise((resolve, reject) => {
    const { getters } = Store;

    function handleBlockedUser() {
      if (getters['users/isUserBlocked']) {
        return reject(
          ClientResponseError.createSimpleError(
            'User blocked',
            HttpStatus.Forbidden,
          ),
        );
      }
    }

    function handleEmailVerification() {
      if (!getters['users/isEmailVerified']) {
        redirect('authURL');

        return reject();
      }
    }

    if (getters['users/hasUser']) {
      handleBlockedUser();
      handleEmailVerification();

      return resolve();
    }

    const userService = new UserStoreService();

    return userService
      .fetchMe()
      .then(() => {
        handleBlockedUser();
        handleEmailVerification();

        return resolve();
      })
      .catch(reject);
  });
}
