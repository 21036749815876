import { QNotifyCreateOptions } from 'quasar/dist/types/index';

export interface SimpleClientErrorParams {
  name: string;
  message: string;
  code?: number | string;
}

const DefaultMessage = 'An unknown error occurred';
const DefaultName = 'An error occurred';

export default class SimpleClientError extends Error {
  name: string = DefaultName;
  message: string = DefaultMessage;
  readonly #notifySettings?: QNotifyCreateOptions;
  code?: number | string;

  /**
   * Tron errors can be a string,
   * @param error
   */
  constructor(
    error: SimpleClientErrorParams | string,
    notifySettings?: QNotifyCreateOptions,
  ) {
    if (typeof error === 'string') {
      super(error);

      this.#initStringError(error);
    } else {
      super(error.message);

      this.#initError(error);
    }

    this.#notifySettings = notifySettings;
  }

  #initStringError(error: string) {
    this.name = 'An error occurred';
    this.message = error;
  }

  #initError(error: SimpleClientErrorParams) {
    const { name, message, code } = error;

    this.name = name;
    this.message = message ?? DefaultMessage;
    this.code = code;
  }

  get notify(): QNotifyCreateOptions {
    return Object.assign(
      {},
      {
        message: this.message,
        type: 'negative',
      },
      this.#notifySettings,
    );
  }
}
