import { DefaultCurrencyPayload } from '@/app/types/api/resources/organizations';
import { StoreAction, StoreCommit } from '@/app/types/store/store';

import Store from '@/store';

import accountsApi from '@/app/api/resources/accounts';
import organizationsApi from '@/app/api/resources/organizations';

export default class {
  fetchOrganizations() {
    return organizationsApi.fetchOrganizations().then(({ data }) => {
      Store.commit(StoreCommit.SetOrganizations, data.data);
      Store.commit(StoreCommit.SetCurrentOrganization, data.data[0].uid);

      return Promise.resolve();
    });
  }

  changeDefaultCurrency(data: DefaultCurrencyPayload) {
    return organizationsApi.changeDefaultCurrency(data).then(() => {
      Promise.all([
        this.fetchOrganizations(),
        this.fetchOrgAccountsWithBalances(),
      ]);
    });
  }

  fetchAccounts() {
    return accountsApi.fetchOrgAccounts().then(({ data }) => {
      const sortedAccounts = [...data.data].sort((a, b) => a.id - b.id);

      Store.commit(StoreCommit.SetOrgAccounts, sortedAccounts);

      return Promise.resolve();
    });
  }

  fetchOrgAccountsWithBalances() {
    return accountsApi.fetchOrgAccountsWithBalances().then(({ data }) => {
      const sortedAccounts = [...data.data].sort((a, b) => a.id - b.id);

      Store.commit(StoreCommit.SetOrgAccounts, sortedAccounts);
      Store.dispatch(StoreAction.SetActiveAccount);
      Store.dispatch(StoreAction.CreateBalancesMap, sortedAccounts);

      return Promise.resolve();
    });
  }

  // TODO: temporary withdraw protection, check again
  fetchAllowedCryptoCurrencies() {
    return organizationsApi
      .fetchAllowedCryptoCurrencies()
      .then(({ data }) =>
        Store.commit(StoreCommit.SetAllowedCryptoCurrencies, data.data),
      );
  }
}
