import { CryptoCurrencySortFields } from '@/app/types/app/currency';
import { SortDirection } from '@/app/types/app/pagination';
import { StoreCommit } from '@/app/types/store/store';

import Store from '@/store';

import dictionariesApi from '@/app/api/resources/dictionaries';
import feesApi from '@/app/api/resources/fees';

export default class {
  fetchCurrencies() {
    return dictionariesApi
      .fetchCurrencies()
      .then(({ data }) => Store.commit(StoreCommit.SetCurrencies, data.data));
  }

  fetchCryptoCurrencies() {
    const requestParams = {
      sorting: {
        field: CryptoCurrencySortFields.Order,
        direction: SortDirection.Ascending,
      },
    };

    return dictionariesApi
      .fetchCryptoCurrencies(requestParams)
      .then(({ data }) =>
        Store.commit(StoreCommit.SetCryptoCurrencies, data.data),
      );
  }

  fetchExchangeRates() {
    return dictionariesApi.fetchExchangeRates().then(({ data }) => {
      const rates = data.data.map((rate) => ({
        ...rate,
        price: parseFloat(rate.price),
      }));

      Store.commit(StoreCommit.SetExchangeRates, rates);
    });
  }

  fetchNetworkFees() {
    return feesApi
      .fetchNetworkFees()
      .then(({ data }) => Store.commit(StoreCommit.SetNetworkFees, data.data));
  }

  fetchDictionaries() {
    return Promise.all([
      this.fetchCurrencies(),
      this.fetchCryptoCurrencies(),
      this.fetchExchangeRates(),
      this.fetchNetworkFees(),
    ]);
  }
}
