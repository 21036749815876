import Store from '@/store';
import DictionariesProcesses from '@/store/services/DictionariesStoreService';
import OrganizationsStoreService from '@/store/services/OrganizationsStoreService';

export default function (): Promise<void> {
  return new Promise((resolve, reject) => {
    const { state } = Store;

    if (state.dictionaries.cryptocurrencies.length) {
      return resolve();
    }

    const dictionariesService = new DictionariesProcesses();
    const orgService = new OrganizationsStoreService();

    return Promise.all([
      dictionariesService.fetchDictionaries(),
      orgService.fetchOrgAccountsWithBalances(),
    ])
      .then(() => resolve())
      .catch(reject);
  });
}
