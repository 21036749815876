
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppSlidesCount',
  props: {
    totalSlides: {
      type: Number,
      required: true,
    },
    activeSlide: {
      type: Number,
      required: true,
    },
  },
});
