export default {
  pagesTitle: {
    dashboard: 'Dashboard',
    transactionsHistory: 'Transactions history',
    outgoingPayments: 'Outgoing payments',
    incomingPayments: 'Incoming payments',
    singlePayment: 'One-time payment',
    webhooks: 'Webhooks',
    businessPayments: 'Incoming payments',
    donations: 'Donation link',
    paylinks: 'Payment link',
    massPayouts: 'Mass payouts',
    withdrawCrypto: 'Withdraw crypto',
    requestWithdrawFiat: 'Withdraw fiat',
    addCrypto: 'Add crypto',
    addFiat: 'Add fiat',
    balances: 'Balances',
    balanceStatement: 'Balance statement',
    contactUs: 'Contact Us',
    exchange: 'Exchange',
    requestsHistory: 'Requests history',
    fundingInstructions: 'Funding instructions',
    orgAccounts: 'Accounts',
    settings: 'Settings',
    gatewayApi: 'Payment gateway API',
    payoutsApi: 'Payouts API',
    merchantSettings: 'Merchant settings',
    payoutsSettings: 'Payouts settings',
    documents: 'Documents',
    invoices: 'Invoices',
    merchantReports: 'Paygate', // clickadu only
    signature: 'Security Key',
  },
  messages: {
    attention: 'Attention!',
    copied: 'Copied!',
    copyToClipboard: 'Copy to clipboard',
    amount: 'Amount',
    amountLowerCase: 'amount',
    to: 'to',
    blockchain: 'Blockchain',
    saveSuccess: 'Changes have been saved',
    here: 'here',
    default: 'Default',
    dateNotSelected: 'Date not selected',
    keepInMind: 'Keep in mind',
    row: 'Row {rowNum}',
    okay: 'Okay',
    filters: 'Filters',
    transactionCreated: 'Transaction successfully created',
    transactionHash: 'Transaction hash',
    totalCryptoBalance: 'Total crypto balance',
    balance: 'Balance',
    fee: 'Fee',
    networkFee: 'Network fee',
    serviceFee: 'Service fee',
    totalAmount: 'Total amount',
    amountAndCurrency: 'Amount and currency',
    amountInCrypto: 'Amount in crypto',
    equivalentInFiat: 'Equivalent in fiat',
    or: 'or',
    max: 'Max',
    calc: 'Calc',
    notAvailable: 'Exchange is not available yet',
    noFilterResults: 'There are no results with these filter settings.',
    tryOtherFilter: 'Try other filter settings.',
    confirmation: 'Confirmation',
    selectDate: 'Select date',
    selectCurrency: 'Select currency',
    selectAccount: 'Select account',
    selectBank: 'Select bank',
    shareVia: 'Share link via',
    sendTo: 'Send to',
    chooseOrDragFile: 'Choose a file or drag it here',
  },
  statuses: {
    active: 'Active',
    processing: 'Processing',
    paused: 'Paused',
    failed: 'Failed',
    pending: 'Pending',
    completed: 'Completed',
    awaiting: 'Awaiting',
    awaitingPartial: 'Awaiting partial',
    canceled: 'Canceled',
    expired: 'Expired',
    partial: 'Partially paid',
    success: 'Success',
    validatingFile: 'File validation',
    verified: 'Verified',
    invalid: 'Invalid',
    readyToPay: 'Ready to pay',
    rejected: 'Rejected',
    illegal: 'Illegal',
    partiallyCompleted: 'Partially completed',
  },
  buttons: {
    addFilter: 'Add filter',
    clearAll: 'Clear all',
    clear: 'Clear',
    payment: 'Payment',
    confirm: 'Confirm',
    tryAgain: 'Try again',
    save: 'Save',
    create: 'Create',
    cancel: 'Cancel',
    dismiss: 'Dismiss',
    delete: 'Delete',
    saveChanges: 'Save changes',
    seeAll: 'See all',
    makePayment: 'Make payment',
    confirmPayment: 'Confirm payment',
    copy: 'Copy',
    regenerate: 'Regenerate',
    generate: 'Generate',
    uploadPhoto: 'Upload photo',
    remove: 'Remove',
    withdraw: 'Withdraw',
    addFunds: 'Add funds',
    resend: 'Resend',
    activate: 'Activate',
    submit: 'Submit',
    template: 'Template',
    newPayout: 'New payout',
    continue: 'Continue',
    uploadFile: 'Upload file',
    reuploadFile: 'Upload another file',
    payNow: 'Pay now',
    next: 'Next',
    back: 'Back',
    done: 'Done',
    skip: 'Skip',
    skipToDashboard: 'Skip to Dashboard',
    saveAs: 'Save as {value}',
    saveAsStatic: 'Save as',
    basic: 'Basic {name}',
    extended: 'Extended {name}',
    add: 'Add',
    exchange: 'Exchange',
    history: 'History',
    manageAccountLink: 'Manage bank accounts',
    confirmWithdraw: 'Confirm withdrawal',
    verify: 'Verify',
    proceed: 'Proceed',
    download: 'Download',
    downloadPdf: 'Download PDF',
    edit: 'Edit',
    settings: 'Settings',
  },
  headings: {
    sender: 'Sender',
    amount: 'Amount',
    paidAmount: 'Amount paid',
    frequency: 'Frequency',
    nextPayment: 'Next payment',
    date: 'Date',
    dateAdded: 'Date added',
    dateSent: 'Date sent',
    requestDate: 'Request date',
    actionDate: 'Action date',
    tradeDate: 'Trade date',
    dateCreated: 'Date created',
    dateUsed: 'Date used',
    currency: 'Currency',
    cryptocurrency: 'Cryptocurrency',
    currencyAmount: 'Currency / Amount',
    type: 'Type',
    paymentName: 'Payment name',
    from: 'from',
    fromUppercase: 'From',
    to: 'to',
    toUppercase: 'To',
    fromTo: 'From/To',
    hash: 'Hash',
    status: 'Status',
    recipient: 'Recipient',
    recipientName: 'Recipient name',
    invoiceId: 'Invoice ID',
    invoiceName: 'Invoice name',
    customer: 'Customer',
    payLink: 'Pay link',
    donator: 'Donator',
    donateNote: 'Donation note',
    payoutName: 'Payout name',
    recipients: 'Recipients',
    total: 'Total',
    totalCurrency: 'Total in {currency}',
    id: 'ID',
    cryptoBlockchain: 'Crypto/Blockchain',
    address: 'Address',
    username: 'Username',
    senderNote: 'Note for sender',
    recipientNote: 'Note for recipient',
    insufficientFunds: 'Insufficient funds',
    coin: 'Coin',
    action: 'Action',
    amountInCrypto: '@:common.messages.amountInCrypto',
    fiatEstimate: 'Fiat estimate',
    fiatAmount: 'Fiat amount',
    fees: 'Fees',
    exchangeRate: 'Exchange rate',
    search: 'Search',
    event: 'Event',
    orderId: 'Order ID',
    refId: 'Ref ID',
    transactionHash: 'Transaction hash',
    selectCoin: 'Select coin and blockchain',
    vatNumber: 'Vat Number',
    name: 'Name',
    bank: 'Bank',
    bankName: 'Bank name',
    accountNum: 'Account Nr.',
    accountName: 'Account name',
    number: 'Number #',
    owner: 'Owner',
    file: 'File',
    iban: 'IBAN',
    account: 'Account',
    inxyAccount: 'INXY account',
    fromAccount: 'From account',
    toAccount: 'To account',
    vat: 'VAT',
    selectedAccount: 'Selected account',
    selectedPeriod: 'Selected period',
    description: 'Description',
    balance: 'Balance',
    in: 'In',
    out: 'Out',
    accountNumber: 'Account number',
    url: 'URL',
    invoiceNumber: 'Invoice #',
    documentNumber: 'Document #',
    defaultCurrency: 'Default currency',
    fullName: 'Full name',
    email: 'Email',
    billingPeriod: 'Billing period',
    featureType: 'Feature type',
    privateKey: 'Private key',
    pemKey: 'PEM key',
    pemFile: 'PEM file',
  },
  date: {
    lowercase: {
      day: 'day',
      month: 'month',
      year: 'year',
      daily: 'daily',
      monthly: 'monthly',
      yearly: 'yearly',
    },
    dayPluralization: 'day | days',
    monthPluralization: 'month | months',
    yearPluralization: 'year | years',
  },
  // common components
  imageUpload: {
    supportedFormats: 'We support JPG and PNG files up to 1 Mb',
  },
  mainHeader: {
    menu: {
      profileSettings: 'Settings',
      logout: 'Log out',
    },
  },
  contactUs: {
    title: 'Do you need our help?',
    description: 'Send your request via email',
    linkText: 'Contact Us',
  },
  sidebar: {
    sendCrypto: 'Send crypto',
    receiveCrypto: 'Receive crypto',
  },
  recipient: '{count} recipient | {count} recipients',
  contactSupport: 'Contact support',
};
