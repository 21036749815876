import Notify from 'quasar/src/plugins/Notify.js';;
import { computed, ref } from 'vue';

import useEntityLoading from '@/composables/common/useEntityLoading';
import UserStoreService from '@/store/services/UserStoreService';

export default function () {
  const isOnboardingInProgress = ref(false);

  const { isLoading, LoadingAction, toggleLoading } = useEntityLoading();

  const isOnboardingLoading = computed(() =>
    isLoading(LoadingAction.CompleteOnboarding),
  );

  function completeOnboarding() {
    const userService = new UserStoreService();

    return userService
      .completeOnboarding()
      .catch(({ notify }) => Notify.create(notify));
  }

  function handleOnboarding(isSkip: boolean) {
    if (isSkip) {
      return completeOnboarding();
    }

    toggleLoading(LoadingAction.CompleteOnboarding);

    completeOnboarding().finally(() =>
      toggleLoading(LoadingAction.CompleteOnboarding),
    );
  }

  return { isOnboardingInProgress, isOnboardingLoading, handleOnboarding };
}
