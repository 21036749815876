import { watch } from 'vue';

import { CryptoBalance } from '@/app/types/api/resources/balances';
import { CryptoCurrency } from '@/app/types/app/currency';
import { StoreAction } from '@/app/types/store/store';

import useSockets, { SocketsEvent } from '@/composables/sockets/useSockets';
import Store from '@/store';

export default function () {
  const { eventData } = useSockets<{
    balance: CryptoBalance<CryptoCurrency, string>;
  }>(SocketsEvent.BalanceChanged);

  watch(
    () => eventData.value?.balance,
    (balance) => {
      if (balance) {
        Store.dispatch(StoreAction.UpdateCryptoBalancesMapManual, balance);
      }
    },
    { deep: true },
  );

  return {};
}
