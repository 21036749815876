import AxiosFactory from '@/app/api/factories/AxiosFactory';
import defaultConfig from '@/app/api/factories/configs/defaultConfig';

export default AxiosFactory.create(
  defaultConfig.baseURL,
  defaultConfig.headers,
  defaultConfig.timeout,
  defaultConfig.requestInterceptors,
  defaultConfig.responseInterceptors,
);
