import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ab4b262"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-onboarding-tooltip__content" }
const _hoisted_2 = { class: "app-onboarding-tooltip__controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, {
    class: "app-onboarding-tooltip",
    "offset-skid": "50",
    arrow: "",
    placement: "auto"
  }, {
    content: _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "content", _normalizeProps(_guardReactiveProps(props)), undefined, true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "controls", {}, undefined, true)
      ]),
      _renderSlot(_ctx.$slots, "status", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }))
}