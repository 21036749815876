import { ComponentPublicInstance, Ref, ref } from 'vue';

import { OnboardingStep } from '@/app/types/api/resources/users';

export enum TooltipAxis {
  Y = 'y',
  X = 'x',
}

const stepRefs: Ref<Record<OnboardingStep, HTMLElement | null>> = ref({
  [OnboardingStep.Balances]: null,
  [OnboardingStep.ReceiveCrypto]: null,
  [OnboardingStep.SendCrypto]: null,
  [OnboardingStep.Settings]: null,
});

export default function () {
  function setOnboardingStepRef(step?: OnboardingStep) {
    if (!step) {
      return;
    }

    return (el: ComponentPublicInstance<HTMLElement>) => {
      stepRefs.value[step] = el?.$el || el;
    };
  }

  function getOnboardingRefPos(step: OnboardingStep, axis: TooltipAxis) {
    const pos = stepRefs.value[step]?.getBoundingClientRect() ?? null;

    if (!pos) {
      return null;
    }

    const { width, height, top, left } = pos;

    const getStylePropValue = (value: number) => `${value}px`;

    const leftOffset = axis === TooltipAxis.Y ? left + width / 2 : left + width;

    return {
      width: getStylePropValue(1),
      height: getStylePropValue(height),
      top: getStylePropValue(top),
      left: getStylePropValue(leftOffset),
    };
  }

  return { setOnboardingStepRef, getOnboardingRefPos };
}
