
import { computed, defineComponent } from 'vue';
import Popper from 'vue3-popper';

export default defineComponent({
  name: 'AppTooltip',
  components: { Popper },
  inheritAttrs: false,
  setup(_, { attrs }) {
    const tooltipAttrs = computed(() => {
      const isHoverActive = 'hover' in attrs && window.Device.isDesktop;

      return { ...attrs, hover: isHoverActive };
    });

    return { tooltipAttrs };
  },
});
