import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  {
    path: '/balances',
    name: RouteName.Balances,
    component: () => import('@/views/ViewBalances/ViewBalances.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/balances/statement',
    name: RouteName.BalanceStatement,
    component: () => import('@/views/ViewBalances/ViewStatement.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/balances/exchange',
    name: RouteName.Exchange,
    component: () => import('@/views/ViewBalances/ViewExchange.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: '/balances/accounts',
    name: RouteName.OrgAccounts,
    component: () => import('@/views/ViewBalances/ViewOrgAccounts.vue'),
    meta: { layout: Layout.Default },
  },
];
