export default {
  maintenance: {
    heading: 'Maintenance in Progress',
    description:
      'We are currently undergoing planned maintenance to enhance our services. Please bear with us as certain features may be temporarily unavailable. We apologize for any inconvenience caused and appreciate your patience.',
  },
  accessRestricted: {
    heading: 'Your account has been banned.',
    description:
      'Your account has been banned as a result of a recent review. If you have any questions or believe there has been an error, please contact us at ',
  },
  notFound: {
    title: 'Oh no! This might be an Error 404!',
    description: {
      first: 'Maybe we have a broken page or this page does not exist.',
      second: 'Either way, let’s get you where you need to go!',
    },
    linkText: 'Back to homepage',
  },
  signature: {
    title: 'Generate and Download Your Security Key',
    description:
      'To ensure the security of your funds, we require each withdrawal request to be signed with an encryption key. By using this key, you prove that you are authorized to make transactions on your account. Your private key encrypts the request, and we decrypt it with our public key. This process confirms the legitimacy of your withdrawal request.',
    note: "Please generate your security key now to proceed with your transactions. If you're unable to do so at this moment, you can come back and complete this step at a more convenient time.",
    tips: [
      'The key is generated only once, and re-creation is available only through our support team.',
      'You will need the key each time you make a withdrawal request.',
      'Keep your key secure. You may also share it with trusted employees if necessary.',
    ],
    actionButton: 'I am ready! Generate key',
    signatureSuccess:
      "Security Key was created successfully. Save it securely when the download prompt appears. You'll be redirected in 5 seconds.",
  },
};
