import '@/assets/ui/quasar.css';
import '@quasar/extras/material-icons/material-icons.css';
import './assets/ui/tailwind.css';
import './assets/ui/variables.css';
import '@/assets/ui/notify.scss';
import './assets/ui/common.css';
import './assets/ui/button.scss';
import './assets/ui/input.scss';
import './assets/ui/fieldInput.scss';
import 'overlayscrollbars/overlayscrollbars.css';
import './assets/ui/overlay-scrollbars.scss';

import Quasar from 'quasar/src/vue-plugin.js';;
import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import VueClickAway from 'vue3-click-away';

import initSockets from '@/plugins/sockets';

import App from './App.vue';
import quasarUserOptions from './app/quasar/quasar-user-options';
import i18n from './plugins/i18n';
import router from './router';
import store from './store';

const initDevice = () => import('@/plugins/device.js');

initDevice();
initSockets();

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(VueClickAway)
  .use(store)
  .use(router)
  .use(i18n)
  .use(createMetaManager())
  .mount('#app');
