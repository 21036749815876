import Store from '@/store';
import VerificationStoreService from '@/store/services/VerificationStoreService';

import SimpleClientError from '@/app/errors/SimpleClientError';
import redirect from '@/app/helpers/redirect';

function handleVerification() {
  redirect('verificationURL');

  throw new SimpleClientError('User not passed verification');
}

export default function (): Promise<void> {
  return new Promise((resolve) => {
    const { state, getters } = Store;

    if (getters['verification/isOrganizationVerified']) {
      return resolve();
    }

    // fetched state but no access
    const hasVerificationState = !!state.verification.verification?.result;

    if (hasVerificationState) {
      handleVerification();
    }

    const verificationService = new VerificationStoreService();

    return verificationService
      .checkVerification()
      .then(() => {
        if (getters['verification/isOrganizationVerified']) {
          return resolve();
        }

        throw '';
      })
      .catch(handleVerification);
  });
}
