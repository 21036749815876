import { AxiosResponse } from 'axios';

import { SortDirection } from '@/app/types/app/pagination';

export enum DictionariesApi {
  Currencies = '/api/dictionaries/currencies',
  CryptoCurrencies = '/api/dictionaries/cryptocurrencies',
  ExchangeRates = '/api/dictionaries/exchange-rates',
}

export type DictionaryResponse<T> = AxiosResponse<{ data: T }>;

export interface RequestSortingParams<Field> {
  field: Field;
  direction: SortDirection;
}

export interface RequestSorting<SortingField> {
  sorting: RequestSortingParams<SortingField>;
}
