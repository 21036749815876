import { DictionaryResponse } from '@/app/types/api/resources/dictionaries';
import {
  DefaultCurrencyPayload,
  Organizations,
  OrganizationsResponse,
} from '@/app/types/api/resources/organizations';
import { CryptoCurrency } from '@/app/types/app/currency';

import client from '@/app/api/client';

export default {
  fetchOrganizations(): Promise<DictionaryResponse<OrganizationsResponse[]>> {
    return client.get(Organizations.Organizations);
  },

  changeDefaultCurrency(
    data: DefaultCurrencyPayload,
  ): Promise<DictionaryResponse<OrganizationsResponse>> {
    return client.put(Organizations.ChangeDefaultCurrency, data);
  },

  fetchAllowedCryptoCurrencies(): Promise<
    DictionaryResponse<CryptoCurrency[]>
  > {
    return client.get(Organizations.AllowedCryptoCurrencies);
  },
};
