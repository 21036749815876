export default {
  crypto: {
    topupFiatLink: 'Add fiat',
    alert: {
      descriptionPartOne:
        'Wallet address is unique for this transaction only and is valid for',
      descriptionPartTwo: "more. It can't be reused!",
    },
    form: {
      topupMethodPlaceholder: 'Select wallet',
      topupMethodLabel: 'Add funds method',
      linkCode: 'Link/QR code',
    },
    tips: [
      { title: 'Initiate a deposit', text: 'Select coin and amount' },
      {
        title: 'Choose deposit method',
        text: 'Chose how you want to add funds',
      },
      {
        title: 'Make a transaction',
        text: 'Transfer funds to the specified wallet address',
      },
      {
        title: 'Deposit is done',
        text: 'Once a transaction is confirmed funds are added to your balance',
      },
    ],
  },
  fiat: {
    topupCryptoLink: 'Add crypto',
    fees: {
      upTo: 'Up to {range}: {value}',
      above: 'Above {range}: {value}',
    },
    tips: [
      {
        title: 'Add bank account',
        text: 'Verify identity and legitimacy by adding bank info.',
      },
      {
        title: 'Get instructions',
        text: 'Receive our bank account and reference number.',
      },
      {
        title: 'Make transaction',
        text: 'Send funds from specified account to our account with reference number.',
      },
      {
        title: 'Money deposited',
        text: 'Funds credited to account balance upon receipt.',
      },
    ],
    noAccount: {
      warningOne:
        'To add and withdraw fiat, you need to add your bank account information, which will be verified to ensure that the funds are coming from a legitimate source. *This verification process may take up to 48 hours.*',
      warningTwo:
        'By providing this information, you can feel confident that your transactions are being processed securely and efficiently, and that our system is working to prevent fraudulent activity and protect your funds.',
    },
    steps: {
      description: 'Add funds to your account using your bank account.',
      stepOne: {
        title: '1. Use a bank that’s linked to INXY',
        note: 'To add funds to your account, make sure to use a linked bank account.',
        bankFee: 'Your bank may impose fees on their end as well.',
        linkedAccounts: 'Linked Bank Accounts',
      },
      stepTwo: {
        title: '2. Create funding Instructions',
        note: 'Obtain the funding instructions for the transaction by providing essential details, such as the linked bank account and the desired amount. Carefully review the provided instructions before confirming to ensure accurate processing of the payment.',
        noteHeader: 'Funding Instructions',
        noteLink: 'Archive',
        noteAction: 'Create funding Instruction',
      },
      stepThree: {
        title: '3. Make a Bank Transfer',
        note: "Next, please follow your bank's instructions for making a payment transfer, using the details provided in funding Instruction. Please ensure that you enter the information exactly as it appears there.  In addition, please be sure to include the reference code provided, as it is required for processing your transaction.",
      },
      stepFour: {
        title: '4. Look for an email',
        note: "We'll send you an email notification once the funds have been added to your balance. Please note that it may take 2-5 business days from the date of arrival at the INXY Payments bank account for the funds to become available in your INXY balance.",
      },
    },
  },
};
