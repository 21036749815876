import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "button-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonLoader = _resolveComponent("ButtonLoader")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(['button', { button_loading: _ctx.isLoading }]),
    type: "button"
  }, [
    _renderSlot(_ctx.$slots, "prepend"),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ButtonLoader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$slots.default !== undefined)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "append")
  ], 2))
}