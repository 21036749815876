// TODO: keep only common

export enum AppEvent {
  Click = 'click',
  ToggleMenu = 'toggle-menu',
  CountdownEnd = 'countdown-end',
  VerifySuccess = 'verify-success',
  PaymentSuccess = 'payment-success',
  Action = 'action',
  Submit = 'submit',
  SubmitCustomForm = 'submit-form',
  Cancel = 'cancel',
  Proceed = 'proceed',
  Update = 'update',
  Resend = 'resend',
  Reset = 'reset',
  Changed = 'changed',
  Success = 'success',
  Fail = 'fail',
  Refresh = 'refresh',
  Validate = 'validate',
  Close = 'close',
  Delete = 'delete',
  MaxAmount = 'max-amount',
  GenerateKey = 'generate',
  Confirm = 'confirm',
  Edit = 'edit',
}

export enum TableEvent {
  RowClick = 'row-click',
}

export enum ModelEvent {
  UpdateModelValue = 'update:modelValue',
  UpdateIsMenuOpened = 'update:isMenuOpened',
  UpdateCurrency = 'update:currency',
  UpdateCryptoCurrency = 'update:cryptocurrency',
  UpdateAmount = 'update:amount',
  UpdateFiatAmount = 'update:fiat-amount',
  UpdateCryptoAmount = 'update:crypto-amount',
  UpdateModalVisibility = 'update:modalVisibility',
  UpdatePagination = 'update:pagination',
  UpdatePage = 'update:page',
  UpdateActive = 'update:active',
  UpdateActiveTab = 'update:activeTab',
  UpdateSelected = 'update:selected',
  UpdateContentVisible = 'update:contentVisible',
  UpdateAccount = 'update:account',
  UpdateInterval = 'update:interval',
  UpdateIntervalCount = 'update:intervalCount',
}

export enum GtmEvent {
  Logout = 'logout',
  TronTransactionSigned = 'tronlink_transaction_signed',
  MetamaskSuccess = 'metamask_transaction_signed',
}
