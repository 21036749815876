
import { computed, defineComponent } from 'vue';

import { AriaState } from '@/app/types/app/common';
import { AppEvent } from '@/app/types/app/events';

import AppButton from '@/components/ui/buttons/AppButton.vue';

export default defineComponent({
  name: 'ButtonMenu',
  components: { AppButton },
  props: {
    isMenuOpened: {
      type: Boolean,
      default: false,
    },
  },
  emits: [AppEvent.ToggleMenu],
  setup(props, { emit }) {
    const menuExpanded = computed<AriaState>(() =>
      props.isMenuOpened ? AriaState.True : AriaState.False,
    );

    function toggleMenu() {
      emit(AppEvent.ToggleMenu, !props.isMenuOpened);
    }

    return {
      menuExpanded,
      toggleMenu,
    };
  },
});
