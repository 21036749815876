export default {
  heading: '@:common.pagesTitle.requestsHistory',
  navigation: {
    bank: 'Bank withdrawals',
    exchange: 'Exchanges',
    withdraw: 'Crypto withdrawals',
  },
  emptyMessage: 'No requests yet',
  newRequests: 'All new requests will appear here.',
};
