<template>
  <svg
    v-bind="$attrs"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    fill="none"
    class="svg-wrapper"
  >
    <slot />
  </svg>
</template>
