import { NavigationGuard } from 'vue-router';

import { RouteName } from '@/app/types/app/routes';

import Store from '@/store';

const maintenanceGuard: NavigationGuard = (to, from, next) => {
  const isServerMaintaining = Store.getters['access/isServerMaintaining'];

  if (!isServerMaintaining) {
    next({ name: RouteName.Dashboard });
  }

  next();
};

export default maintenanceGuard;
