
import { defineComponent } from 'vue';

import { ModelEvent } from '@/app/types/app/events';

import MainHeaderUser from '@/components/common/layout/Header/User/MainHeaderUser.vue';
import ButtonMenu from '@/components/ui/buttons/ButtonMenu.vue';

export default defineComponent({
  name: 'MainHeader',
  components: {
    ButtonMenu,
    MainHeaderUser,
  },
  props: {
    isMenuOpened: {
      type: Boolean,
      default: false,
    },
  },
  emits: [ModelEvent.UpdateIsMenuOpened],
  setup(_, { emit }) {
    function toggleMenu(isMenuOpened: boolean) {
      emit(ModelEvent.UpdateIsMenuOpened, isMenuOpened);
    }

    return { toggleMenu };
  },
});
